import React, { Suspense } from "react";
import * as Sentry from "@sentry/react";
import { Loader } from "./app/components/core/components/Loader";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { NonAuthNavigation } from "./app/routes/NonAuthNavigation";
import packageInfo from "../package.json";
import { AuthNavigation } from "./app/routes/AuthNavigation";
import { ReduxStore } from "./Store";
import { Provider } from "react-redux";
import { RelayEnvironmentProvider } from "react-relay";
import { RelayEnvironment } from "./app/relay/RelayEnvironment";
import { AuthenticationFallback } from "./app/components/auth/AuthenticationFallback";
import ClearCache from "react-clear-cache";

const isProduction = process.env.REACT_APP_APP_ENVIRONMENT === "prod";

if (isProduction) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
	});
}

function App() {
	return (
		<Provider store={ReduxStore}>
			<ToastContainer autoClose={5000} newestOnTop={true} />
			<Sentry.ErrorBoundary onError={(e) => console.error(e)}>
				<ClearCache auto duration={60000}>
					{() => (
						<Suspense fallback={<Loader />}>
							<RelayEnvironmentProvider environment={RelayEnvironment}>
								<BrowserRouter>
									<AuthenticationFallback loginFallback={<NonAuthNavigation />}>
										<AuthNavigation />
									</AuthenticationFallback>
								</BrowserRouter>
							</RelayEnvironmentProvider>
						</Suspense>
					)}
				</ClearCache>
			</Sentry.ErrorBoundary>
			<div className="hidden">Version: {packageInfo.version}</div>
		</Provider>
	);
}

export default App;
