import React from "react";
import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { ChangeEmailForm_ChangeEmailMutation } from "../../../__generated__/ChangeEmailForm_ChangeEmailMutation.graphql";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../core/components/form/ValidatedField";
import { toast } from "react-toastify";
import { DefaultTextFieldComponent } from "../core/components/form/DefaultTextInput";
import { ChangeEmailForm_UserFragment$key } from "../../../__generated__/ChangeEmailForm_UserFragment.graphql";

const USER_FRAGMENT = graphql`
	fragment ChangeEmailForm_UserFragment on UserInformation {
		isLocked
	}
`;

const CHANGE_EMAIL_MUTATION = graphql`
	mutation ChangeEmailForm_ChangeEmailMutation($input: ChangeEmailInput!) {
		InfectopharmAuth {
			changeEmail(input: $input) {
				clientMutationId
			}
		}
	}
`;

interface FormState {
	email: string;
	email2: string;
}

interface OwnProps {
	userFragmentRef: ChangeEmailForm_UserFragment$key;
}

export const ChangeEmailForm = ({ userFragmentRef }: OwnProps) => {
	const user = useFragment<ChangeEmailForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef);
	const [changePassword, isChanging] =
		useMutation<ChangeEmailForm_ChangeEmailMutation>(CHANGE_EMAIL_MUTATION);
	const formik = useFormik<FormState>({
		initialValues: {
			email: "",
			email2: "",
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt."),
			email2: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt.")
				.oneOf([Yup.ref("email"), undefined], "E-Mails müssen übereinstimmen."),
		}),
		onSubmit: (values) => {
			changePassword({
				variables: {
					input: {
						value: values.email,
					},
				},
				onCompleted: () => {
					toast("E-Mail erfolgreich geändert.", { type: "success" });
					formik.resetForm();
				},
			});
		},
	});
	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"email"}
				label={"Neue E-Mail"}
				iconClass={"pi-envelope"}
				required={true}
				disabled={user.isLocked}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<FormState, string>
				name={"email2"}
				label={"E-Mail (wiederholen)"}
				iconClass={"pi-envelope"}
				required={true}
				disabled={user.isLocked}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<Button disabled={isChanging || user.isLocked} label={"Speichern"} />
		</form>
	);
};
