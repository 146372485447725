const wrongPhoneOrFaxNumberMessage = (numberType: string): string => {
	return `Die ${numberType}nummer muss im internationalen Format (z.B. +49 und ohne 0 oder Leerzeichen) angegeben werden.`;
};

export const ERROR_MESSAGES: { [key: string]: string } = {
	"auth-unspecified-auth-exception":
		"Login fehlgeschlagen. Bitte überprüfe Benutzername und Passwort.",
	"auth-user-not-activated":
		"Sie haben Ihr Konto noch nicht aktiviert - bitte überprüfen Sie Ihr E-Mail Postfach.",
	"auth-token-unknown": "Dieser Aktivierungs-Link ist nicht gültig.",
	"auth-already-activated": "Dieses Konto ist bereits aktiviert.",
	"auth-doccheck-validation-failed": "Dieser Login konnte nicht validiert werden.",
	"auth-efn-number-already-in-use": "Diese EFN / ÖÄK-Arztnummer wird bereits verwendet.",
	infectopharmauth_cant_remove_all_roles_exception:
		"Es können nicht alle Rollen entfernt werden.",
	"infectopharmauth_cant-delete-only-admin-exception":
		"Der einzige Administrator kann nicht entfernt werden",
	"infectopharmauth_email-change-failed-exception":
		"Das zurücksetzen der E-Mail ist fehlgeschlagen. Bitte überprüfen Sie das Format.",
	infectopharmauth_efn_number_has_wrong_format:
		"Diese EFN / ÖÄK-Arztnummer hat ein ungültiges Format.",
	infectopharmauth_efn_number_already_in_use:
		"Diese EFN / ÖÄK-Arztnummer wird bereits von einem anderen Benutzer verwendet.",
	infectopharmauth_phone_number_has_wrong_format: wrongPhoneOrFaxNumberMessage("Telefon"),
	infectopharmauth_fax_number_has_wrong_format: wrongPhoneOrFaxNumberMessage("Fax"),
	"user-not-activated": "Ihre E-Mail-Adresse ist nicht bestätigt.",
	auth_auth_authenticate_unspecified_auth:
		"Login fehlgeschlagen. Bitte überprüfe Benutzername und Passwort.",
	infectopharmauth_login_with_email_and_password_unspecified_auth:
		"Login fehlgeschlagen. Bitte überprüfen Sie Benutzername und Passwort.",
	infectopharmauth_already_signed_up: "Diese E-Mail Adresse wird bereits verwendet.",
	infectopharmauth_doc_check_validation_failed: "Dieser Login konnte nicht validiert werden.",
	infectopharmauth_user_not_found_by_email:
		"Es wurde kein Benutzer mit dieser E-Mail-Adresse gefunden",
	infectopharmauth_email_already_in_use: "Ein Nutzer mit dieser E-Mail existiert bereits.",
	infectopharmauth_email_invalid: "Die eingegebene E-Mail-Adresse ist ungültig.",
	infectopharmauth_signup_process_doesnt_exist:
		"Registrierungsprozess ist abgelaufen. Bitte registrieren Sie sich erneut.",
};

export const errorMessageColor = "#e74c3c";
