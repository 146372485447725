import React, { useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
	SignupProcessStatus,
	StartSignupStep_CheckEmailMutation,
} from "../../../../__generated__/StartSignupStep_CheckEmailMutation.graphql";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { Message } from "primereact/message";
import { StartSignupStep_BrandFragment$key } from "../../../../__generated__/StartSignupStep_BrandFragment.graphql";
import { NavLink } from "react-router-dom";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import styled from "styled-components";
import { errorMessageColor } from "../../core/errors/ERROR_MESSAGES";
import { StartSignupStep_Query } from "../../../../__generated__/StartSignupStep_Query.graphql";
import { useDialogLogic } from "../../core/components/dialog/useDialogLogic";

const PRIVACY_QUERY = graphql`
	query StartSignupStep_Query($brandId: ID!) {
		Viewer {
			Privacy {
				LatestPrivacy(brandId: $brandId) {
					id
					content
				}
			}
		}
	}
`;

const BRAND_FRAGMENT = graphql`
	fragment StartSignupStep_BrandFragment on Brand {
		id
		icon {
			url
		}
		name
		brandColorHex
	}
`;

const CHECK_EMAIL_MUTATION = graphql`
	mutation StartSignupStep_CheckEmailMutation($input: StartSignupProcessInput!) {
		Signup {
			startSignupProcess(input: $input) {
				signupProcess {
					id
					status
				}
			}
		}
	}
`;

interface FormState {
	email: string;
	email2: string;
	password: string;
	password2: string;
	legal: boolean;
}

interface OwnProps {
	brandFragment: StartSignupStep_BrandFragment$key;
	advance: (newStatus: SignupProcessStatus, id: string) => void;
	stateStep?: string;
}

export const StartSignupStep = ({ brandFragment, advance, stateStep }: OwnProps) => {
	const [testAccount, isRunning] =
		useMutation<StartSignupStep_CheckEmailMutation>(CHECK_EMAIL_MUTATION);

	const { dialogComponent, showDialog } = useDialogLogic();

	const [emailExists, setEmailExists] = useState<boolean>(false);

	const brand = useFragment<StartSignupStep_BrandFragment$key>(BRAND_FRAGMENT, brandFragment);

	const isPaedia = brand.id === process.env.REACT_APP_PAEDIA_ID;

	const {
		Viewer: {
			Privacy: { LatestPrivacy },
		},
	} = useLazyLoadQuery<StartSignupStep_Query>(
		PRIVACY_QUERY,
		{
			brandId: brand.id,
		},
		{ fetchPolicy: "network-only" },
	);

	const formik = useFormik<FormState>({
		initialValues: {
			email: "",
			email2: "",
			password: "",
			password2: "",
			legal: false,
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt."),
			email2: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt.")
				.oneOf([Yup.ref("email"), undefined], "E-Mails müssen übereinstimmen."),
			password: Yup.string()
				.min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
				.required("Das Feld Passwort wird benötigt"),
			password2: Yup.string()
				.oneOf([Yup.ref("password"), undefined], "Passwörter müssen übereinstimmen.")
				.required("Das Feld E-Mail wird benötigt."),
			legal: Yup.boolean()
				.oneOf([true], "Das Feld ist benötigt.")
				.required("Das Feld ist benötigt."),
		}),
		onSubmit: (values) => {
			testAccount({
				variables: {
					input: {
						email: values.email,
						password: values.password,
						brandRef: brand.id,
						privacyAcceptanceText: "Ich habe die Datenschutzbestimmungen gelesen.",
					},
				},
				onCompleted: (result) => {
					advance(
						result.Signup.startSignupProcess?.signupProcess.status!,
						result.Signup.startSignupProcess?.signupProcess.id!,
					);
				},
				onError: () => {
					setEmailExists(true);
				},
			});
		},
	});

	return (
		<div>
			<h1 className="mb-4">Jetzt registrieren</h1>
			<div className="text-sm mb-4">
				<p>
					Hier können Sie als Angehörige(r) des Fachkreises im Sinne des §2
					Heilmittelwerbegesetz (und in Deutschland tätig) einmalig Ihr Benutzerkonto bei{" "}
					{brand.name} anlegen. Sie erhalten dann vollen Zugriff auf die nach dem
					Heilmittelwerbegesetz geschützten Seiten und den kompletten Service von{" "}
					{brand.name}. Geben Sie zur Registrierung bitte Ihre Daten an und klicken dann
					auf „Weiter“.
				</p>

				<LinkNote
					brandColor={brand.brandColorHex}
					target="_blank"
					rel="noopener noreferrer"
					href={
						isPaedia
							? "https://www.paedia.de/hinweise-zur-registrierung/"
							: "https://www.infectopharm.com/hinweise-zur-registrierung/"
					}
				>
					Hinweise zur Registrierung
				</LinkNote>
			</div>
			{stateStep === "Finished" && (
				<ErrorParag className="text-sm mb-4">
					Ein Benutzerprofil mit dieser E-Mail existiert bereits
				</ErrorParag>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"email"}
					label={"E-Mail"}
					iconClass={"pi-envelope"}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<InputText
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>

				<ValidatedField<FormState, string>
					name={"email2"}
					label={"E-Mail (wiederholen)"}
					iconClass={"pi-envelope"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<InputText
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>

				<ValidatedField<FormState, string>
					name={"password"}
					label={"Passwort"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<Password
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								toggleMask
								feedback={true}
								autoComplete={"new-password"}
								weakLabel={"Schwach"}
								mediumLabel={"Mittel"}
								strongLabel={"Stark"}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>
				<ValidatedField<FormState, string>
					name={"password2"}
					label={"Passwort (wiederholen)"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<Password
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								toggleMask
								feedback={false}
								autoComplete={"new-password"}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>

				<ValidatedField<FormState, boolean>
					name={"legal"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName }) => {
						return (
							<div className="p-col-12">
								<StyledCheckbox
									brandColor={brand.brandColorHex}
									className="mr-2"
									inputId={fieldName}
									onChange={(e) => updateField(e.checked)}
									checked={fieldValue!}
								/>
								{dialogComponent}
								<label htmlFor={fieldName} className="p-checkbox-label">
									Ich habe die{" "}
									<LinkNote
										brandColor={brand.brandColorHex}
										rel="noopener noreferrer"
										href={"#"}
										onClick={() =>
											showDialog({
												title: "Datenschutzbestimmungen",
												content: LatestPrivacy?.content as string,
												dialogCallback: () => {},
											})
										}
									>
										Datenschutzbestimmungen
									</LinkNote>{" "}
									gelesen.*
								</label>
							</div>
						);
					}}
				/>

				{emailExists && (
					<Message
						className="mb-2"
						severity="error"
						content={
							<span>
								Diese E-Mail Adresse wird bereits verwendet. Wenn Sie Inhaber dieser
								E-Mail Adresse sind, loggen Sie sich bitte im bestehenden Konto ein.
								Wenn Sie die Zugangsdaten für das bestehende Konto vergessen haben,
								nutzen Sie bitte unsere{" "}
								<NavLink to="/forgot-password">Passwort vergessen</NavLink>
								-Funktion.
							</span>
						}
					/>
				)}
				<Button disabled={isRunning} type="submit" label="Weiter" className="p-mt-2" />
			</form>
		</div>
	);
};

export interface ColorProps {
	brandColor?: string | null;
}
const ErrorParag = styled.p`
	color: ${errorMessageColor} !important;
`;
const LinkNote = styled.a<ColorProps>`
	color: ${(p) => p.brandColor};
`;

export const StyledCheckbox = styled(Checkbox)<ColorProps>`
	.p-checkbox-box.p-highlight {
		border-color: ${(props) => props.brandColor}!important;
		background-color: ${(props) => props.brandColor} !important;
	}
	.p-focus {
		border-color: ${(props) => props.brandColor} !important;
	}
`;
