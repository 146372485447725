import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { RenderConfig } from "./ValidatedField";

export function DefaultTextFieldComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	preventTrimStart,
}: RenderConfig<string>) {
	return (
		<InputText
			id={fieldName}
			name={fieldName}
			value={fieldValue}
			onChange={(e) =>
				preventTrimStart
					? updateField(e.target.value.trimStart())
					: updateField(e.target.value)
			}
			disabled={disabled}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}
