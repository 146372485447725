import { NonAuthScreenBase } from "../components/core/components/base/NonAuthScreenBase";
import { ProgressSpinner } from "primereact/progressspinner";
import { graphql } from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { useMutation } from "react-relay";
import { DocCheckLoginSuccessScreen_LoginMutation } from "../../__generated__/DocCheckLoginSuccessScreen_LoginMutation.graphql";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Message } from "primereact/message";
import { DocCheckState } from "./DocCheckLoginScreen";
import { RRNLRequestError } from "react-relay-network-modern";
import { ERROR_MESSAGES } from "../components/core/errors/ERROR_MESSAGES";
import { JwtTokenData, setLoggedIn } from "../redux/slices/AuthSlice";
import { redirectAfterAuthentication } from "../components/auth/utils/redirect-after-authentication";
import { useDispatch } from "react-redux";

const LOGIN_MUTATION = graphql`
	mutation DocCheckLoginSuccessScreen_LoginMutation($input: DocCheckLoginInput!) {
		InfectopharmAuth {
			docCheckLogin(input: $input) {
				accessToken
				refreshToken
			}
		}
	}
`;

interface State {
	errorMessage?: string;
}

export const DocCheckLoginSuccessScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [state, setState] = useState<State>();

	const [doccheckLogin, isLoggingIn] =
		useMutation<DocCheckLoginSuccessScreen_LoginMutation>(LOGIN_MUTATION);

	const code = searchParams.get("code");
	const uniqueKey = searchParams.get("uniquekey");
	const stateString = decodeURI(searchParams.get("state")!);

	useEffect(() => {
		if (!code || !uniqueKey || !stateString) {
			setState({
				errorMessage: "Der DocCheck-Login hat nicht funktioniert.",
			});
		} else {
			const state = JSON.parse(atob(stateString)) as DocCheckState;

			if (!state) {
				setState({
					errorMessage: "Dieser DocCheck Login Link ist nicht gültig",
				});
			} else {
				doccheckLogin({
					variables: {
						input: {
							loginCode: code,
							uniqueKey: uniqueKey,
							brandRef: state.brandRef,
						},
					},
					onCompleted: (r) => {
						navigate("/dashboard");

						dispatch(
							setLoggedIn({
								tokenData: r.InfectopharmAuth.docCheckLogin as JwtTokenData,
							}),
						);

						if (state.redirectBase64 !== "undefined") {
							redirectAfterAuthentication(
								state.redirectBase64,
								r.InfectopharmAuth.docCheckLogin as JwtTokenData,
							);
						}
					},
					onError: (error) => {
						if (error instanceof RRNLRequestError) {
							const message = error.res?.errors?.find(() => true)?.message;

							setState({
								errorMessage: message ? ERROR_MESSAGES[message.toLowerCase()] : "",
							});
						}
					},
				});
			}
		}
		// eslint-disable-next-line
	}, []);

	return (
		<NonAuthScreenBase>
			<h1>Per DocCheck einloggen</h1>
			{isLoggingIn && (
				<>
					<p>Sie werden eingeloggt...</p>
					<ProgressSpinner />
				</>
			)}
			{state?.errorMessage && <Message severity={"error"} content={state.errorMessage} />}
		</NonAuthScreenBase>
	);
};
