import { Route, Routes } from "react-router-dom";
import { DashboardModuleRoutes } from "./DashboardModuleRoutes";
import { SupplyEfnModuleRoutes } from "./SupplyEfnModuleRoutes";
import { DashboardScreen } from "../screens/DashboardScreen";
import { DOCCHECK_LOGIN_PATH, LOGIN_PATH } from "./NoLoginAuthModuleRoutes";
import { DocCheckRedirectScreen } from "../screens/DocCheckRedirectScreen";
import { LOGIN_WITH_TOKEN_PATH, TokenLoginScreen } from "../screens/TokenLoginScreen";
import { LOGOUT_PATH, LogoutScreen } from "../screens/LogoutScreen";
import { NotAuthorizedModuleRoutes } from "./NotAuthorizedModuleRoutes";

export const AuthNavigation = () => {
	return (
		<Routes>
			{[
				...DashboardModuleRoutes,
				...SupplyEfnModuleRoutes,
				...NotAuthorizedModuleRoutes,
				{
					path: DOCCHECK_LOGIN_PATH,
					component: DocCheckRedirectScreen,
				},
				{
					path: LOGIN_WITH_TOKEN_PATH,
					component: TokenLoginScreen,
				},
				{
					path: LOGOUT_PATH,
					component: LogoutScreen,
				},
				{
					path: LOGIN_PATH,
					component: DashboardScreen,
				},
				{
					path: "*",
					component: DashboardScreen,
				},
			].map((prd) => {
				const Component = prd.component as any;

				return <Route key={prd.path} path={prd.path} element={<Component />} />;
			})}
		</Routes>
	);
};
