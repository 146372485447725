import React from "react";
import MaintenanceBg from "../assets/bg-maintenance.jpg";
import styled from "styled-components";
import Wissenwirkt from "../assets/wissenwirkt.png";
import MaintenanceBgSmall from "../assets/bg-maintenance-smallscreen.png";

const deviceWidth = window.innerWidth;
export const MaintenanceScreen = () => {
	return (
		<PageContainer imageurl={deviceWidth > 991 ? MaintenanceBg : MaintenanceBgSmall}>
			<CardContainer>
				<CardMaintenance>
					<img alt="InfectoPharm" src={Wissenwirkt} width={70} />
					<DescriptionContainer>
						<h3 className="mt-4 mb-0 text-center">
							Die Lernplattform WissenWirkt ist zur Zeit wegen Wartungsarbeiten
							geschlossen.
						</h3>
					</DescriptionContainer>
				</CardMaintenance>
			</CardContainer>
		</PageContainer>
	);
};

interface ImageProps {
	imageurl?: string | null;
}

export const PageContainer = styled.div<ImageProps>`
	width: 100%;
	height: 100%;
	background-image: url(${(p) => p.imageurl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;
export const CardContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 40px;
`;
const CardMaintenance = styled.div`
	background-color: #545e60;
	width: 500px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;
	margin: 10px;
`;

export const DescriptionContainer = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
