import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "../../../Store";
import { Salutation } from "../../../__generated__/DashboardScreen_UserStatusFragment.graphql";
import { InfectopharmRole } from "../../../__generated__/SupplyEfnForm_UserFragment.graphql";
import { ContactDetailsFormState } from "../../components/roles/roleunlock/ContactDetailsFormPart";
import { AddressFormState } from "../../components/roles/roleunlock/AddressFormPart";

export type FormStateSignUp = ContactDetailsFormState &
	AddressFormState & {
		efnNumber?: string;
		fachrichtung?: string;
		targetRole?: InfectopharmRole;
		salutation: Salutation;
		title: string;
		firstName: string;
		lastName: string;
	};

export const INITIAL_STATE = {
	salutation: "Unknown",
	title: "",
	firstName: "",
	lastName: "",
	phone: "",
	country: "Deutschland",
} as FormStateSignUp;

const signUpProcessSlice = createSlice({
	name: "signUpProcess",
	initialState: INITIAL_STATE,
	reducers: {
		setSignUpUserData(state, action: PayloadAction<FormStateSignUp>) {
			state = action.payload;
			localStorage.setItem("signUp-process", JSON.stringify(action.payload));
		},
	},
});

export const { setSignUpUserData } = signUpProcessSlice.actions;
export const SignUpProcessSliceReducer = signUpProcessSlice.reducer;

const selectSignUpProcessSlice = (state: ReduxState) => state.signUpProcess;

export const signUpProcessData = createSelector(selectSignUpProcessSlice, (state) => state);
