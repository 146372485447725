/**
 * @generated SignedSource<<e4a4a19a05e7411abd8894f11164d1a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResendActivationMutationInput = {
  brandId: string;
  clientMutationId?: string | null;
  signUpProcessId: string;
};
export type ResendEmailValidation_ActivationMutation$variables = {
  input: ResendActivationMutationInput;
};
export type ResendEmailValidation_ActivationMutation$data = {
  readonly InfectopharmAuth: {
    readonly userResendActivationEmail: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type ResendEmailValidation_ActivationMutation = {
  response: ResendEmailValidation_ActivationMutation$data;
  variables: ResendEmailValidation_ActivationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InfectopharmAuthMutations",
    "kind": "LinkedField",
    "name": "InfectopharmAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ResendActivationMutationPayload",
        "kind": "LinkedField",
        "name": "userResendActivationEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResendEmailValidation_ActivationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResendEmailValidation_ActivationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "315561ad157ead1980e1b0a773acbb21",
    "id": null,
    "metadata": {},
    "name": "ResendEmailValidation_ActivationMutation",
    "operationKind": "mutation",
    "text": "mutation ResendEmailValidation_ActivationMutation(\n  $input: ResendActivationMutationInput!\n) {\n  InfectopharmAuth {\n    userResendActivationEmail(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0a61ffc1da46458816bb6bbfdade6cf";

export default node;
