/**
 * @generated SignedSource<<455fdd09d7a1a2691357e24c20900e82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "Unknown" | "%future added value";
export type RegistrationDisplayLocation = "Profile" | "Registration" | "%future added value";
export type SignupProcessScreen_Query$variables = {
  id: string;
  skip: boolean;
};
export type SignupProcessScreen_Query$data = {
  readonly node?: {
    readonly newsletters?: ReadonlyArray<{
      readonly countries: ReadonlyArray<InfectopharmCountry>;
      readonly description: string;
      readonly id: string;
      readonly name: string;
      readonly registrationDisplayLocations: ReadonlyArray<RegistrationDisplayLocation>;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"NeedsEmailValidationStep_BrandFragment" | "NewsletterDataStep_BrandFragment" | "NonAuthScreenBase_BrandFragment" | "PersonalAndProfessionalDataStep_BrandFragment" | "StartSignupStep_BrandFragment">;
  } | null;
};
export type SignupProcessScreen_Query = {
  response: SignupProcessScreen_Query$data;
  variables: SignupProcessScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Newsletter",
  "kind": "LinkedField",
  "name": "newsletters",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDisplayLocations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countries",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupProcessScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "NonAuthScreenBase_BrandFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StartSignupStep_BrandFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PersonalAndProfessionalDataStep_BrandFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "NewsletterDataStep_BrandFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "NeedsEmailValidationStep_BrandFragment"
                  },
                  (v4/*: any*/)
                ],
                "type": "Brand",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignupProcessScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "brandColorHex",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "icon",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availableRoles",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "type": "Brand",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1ec753e866012dfb5d59f2fab8d2cbd0",
    "id": null,
    "metadata": {},
    "name": "SignupProcessScreen_Query",
    "operationKind": "query",
    "text": "query SignupProcessScreen_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Brand {\n      ...NonAuthScreenBase_BrandFragment\n      ...StartSignupStep_BrandFragment\n      ...PersonalAndProfessionalDataStep_BrandFragment\n      ...NewsletterDataStep_BrandFragment\n      ...NeedsEmailValidationStep_BrandFragment\n      newsletters {\n        id\n        name\n        description\n        registrationDisplayLocations\n        countries\n      }\n    }\n    id\n  }\n}\n\nfragment NeedsEmailValidationStep_BrandFragment on Brand {\n  id\n  availableRoles\n  brandColorHex\n}\n\nfragment NewsletterDataStep_BrandFragment on Brand {\n  id\n  availableRoles\n  brandColorHex\n}\n\nfragment NonAuthScreenBase_BrandFragment on Brand {\n  name\n  brandColorHex\n  icon {\n    url\n    id\n  }\n}\n\nfragment PersonalAndProfessionalDataStep_BrandFragment on Brand {\n  id\n  availableRoles\n  brandColorHex\n}\n\nfragment StartSignupStep_BrandFragment on Brand {\n  id\n  icon {\n    url\n    id\n  }\n  name\n  brandColorHex\n}\n"
  }
};
})();

(node as any).hash = "1994b7996f48be429d3e5cb0aa4fedcc";

export default node;
