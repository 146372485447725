import { useFormik } from "formik";
import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { PTARoleApplicationForm_ApplyRoleMutation } from "../../../../__generated__/PTARoleApplicationForm_ApplyRoleMutation.graphql";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { AddressFormPart, AddressFormState } from "./AddressFormPart";
import { ContactDetailsFormPart, ContactDetailsFormState } from "./ContactDetailsFormPart";
import { NewsletterFormPart, NewsletterFormState } from "./NewsletterFormPart";
import { PTARoleApplicationForm_BrandFragment$key } from "../../../../__generated__/PTARoleApplicationForm_BrandFragment.graphql";
import { AddressValidationHelper } from "../../signup/helpers/countryRelatedHelpers";

const BRAND_FRAGMENT = graphql`
	fragment PTARoleApplicationForm_BrandFragment on Brand {
		...NewsletterFormPart_BrandFragment
	}
`;

type FormState = ContactDetailsFormState & AddressFormState & NewsletterFormState & {};

const APPLY_APOTHECARY_MUTATION = graphql`
	mutation PTARoleApplicationForm_ApplyRoleMutation($input: ApplyForPTARoleInput!) {
		Roles {
			applyForPTARole(input: $input) {
				userStatus {
					signedUpOverBrand {
						brandUrl
					}
					...AuthScreenBase_UserInformationFragment
				}
			}
		}
	}
`;

interface OwnProps {
	brandFragmentRef: PTARoleApplicationForm_BrandFragment$key | null;
	applicationToken?: string;
	onFinished?: (brandUrl?: string) => void;
}

export const PTARoleApplicationForm = ({
	brandFragmentRef,
	applicationToken,
	onFinished,
}: OwnProps) => {
	const [applyForRole, isApplyingForRole] =
		useMutation<PTARoleApplicationForm_ApplyRoleMutation>(APPLY_APOTHECARY_MUTATION);
	const brand = useFragment<PTARoleApplicationForm_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandFragmentRef,
	);

	const formik = useFormik<FormState>({
		initialValues: {
			fax: "",
			phone: "",
			street: "",
			postalCode: "",
			city: "",
			country: "Deutschland",
		},
		validationSchema: Yup.object().shape(AddressValidationHelper),
		onSubmit: (values) => {
			applyForRole({
				variables: {
					input: {
						applicationToken: applicationToken,
						contactDetails: {
							faxNumber: values.fax,
							phoneNumber: values.phone,
						},
						practiceAddress: {
							company: values.company,
							street: values.street!,
							postalCode: values.postalCode!,
							city: values.city!,
							country: values.country!,
						},
						subscribedNewsletterRefs: [...(values.subscribedNewsletterRefs || [])],
					},
				},
				onCompleted: (data) => {
					toast("Rolle erfolgreich beantragt.", { type: "success" });
					if (onFinished)
						onFinished(
							data.Roles.applyForPTARole?.userStatus.signedUpOverBrand?.brandUrl,
						);
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ContactDetailsFormPart formik={formik} />
			<AddressFormPart formik={formik} />
			<NewsletterFormPart brandFragmentRef={brand} role={"PTA"} formik={formik} />

			<Button
				disabled={isApplyingForRole}
				type="submit"
				label="Registrierung abschließen"
				className="mt-2 mb-2"
			/>
		</form>
	);
};
