/**
 * @generated SignedSource<<994801fe40b97c2e62a959d63d6791e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserHasNoAuthorisationScreen_Brand_Query$variables = {
  id: string;
  skip: boolean;
};
export type UserHasNoAuthorisationScreen_Brand_Query$data = {
  readonly node?: {
    readonly brandColorHex?: string;
    readonly brandUrl?: string;
    readonly icon?: {
      readonly name: string;
      readonly url: string | null;
    } | null;
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserHasNoAuthorisationScreen_BrandFragment">;
  } | null;
};
export type UserHasNoAuthorisationScreen_Brand_Query = {
  response: UserHasNoAuthorisationScreen_Brand_Query$data;
  variables: UserHasNoAuthorisationScreen_Brand_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandColorHex",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brandUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHasNoAuthorisationScreen_Brand_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "icon",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserHasNoAuthorisationScreen_BrandFragment"
                  }
                ],
                "type": "Brand",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserHasNoAuthorisationScreen_Brand_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "icon",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Brand",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "6fac27187151babe7d56ff502714ffd1",
    "id": null,
    "metadata": {},
    "name": "UserHasNoAuthorisationScreen_Brand_Query",
    "operationKind": "query",
    "text": "query UserHasNoAuthorisationScreen_Brand_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on Brand {\n      id\n      brandColorHex\n      brandUrl\n      icon {\n        url\n        name\n        id\n      }\n      ...UserHasNoAuthorisationScreen_BrandFragment\n    }\n    id\n  }\n}\n\nfragment UserHasNoAuthorisationScreen_BrandFragment on Brand {\n  id\n  brandColorHex\n  brandUrl\n  icon {\n    url\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d16adac4b188be414bfab72f28145898";

export default node;
