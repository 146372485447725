/**
 * @generated SignedSource<<3f08816e3f959a2cf801a9688c52cc6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NonAuthScreenBase_BrandFragment$data = {
  readonly brandColorHex: string;
  readonly icon: {
    readonly url: string | null;
  } | null;
  readonly name: string;
  readonly " $fragmentType": "NonAuthScreenBase_BrandFragment";
};
export type NonAuthScreenBase_BrandFragment$key = {
  readonly " $data"?: NonAuthScreenBase_BrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NonAuthScreenBase_BrandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NonAuthScreenBase_BrandFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandColorHex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

(node as any).hash = "05394772846f76c47465549fc0c6bf56";

export default node;
