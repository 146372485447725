import { useMatch } from "react-router-dom";
import { NonAuthScreenBase } from "../components/core/components/base/NonAuthScreenBase";
import { DOCCHECK_LOGIN_PATH } from "../routes/NoLoginAuthModuleRoutes";
import styled from "styled-components";

export interface DocCheckState {
	redirectBase64: string;
	brandRef: string;
}

export const DocCheckLoginScreen = () => {
	const match = useMatch(DOCCHECK_LOGIN_PATH);

	const clientId = process.env.REACT_APP_DOCCHECK_LOGIN_ID;

	const stateParams = btoa(
		JSON.stringify({
			redirectBase64: match?.params.redirectBase64,
			brandRef: match?.params.brandId!,
		}),
	);

	return (
		<NonAuthScreenBase>
			<h1>Per DocCheck einloggen</h1>
			<StyledIframe
				title="DocCheck Login"
				frameBorder="0"
				scrolling="no"
				name="dc_login_iframe"
				id="dc_login_iframe"
				src={`https://login.doccheck.com/code/de/${clientId}/login_xl/?state=${stateParams}`}
			>
				<a
					rel="noreferrer noopener"
					href={`https://login.doccheck.com/code/de/${clientId}/login_xl/?state=${stateParams}`}
					target="_blank"
				>
					LOGIN
				</a>
			</StyledIframe>
		</NonAuthScreenBase>
	);
};

const StyledIframe = styled.iframe`
	width: 100%;
`;
