/**
 * @generated SignedSource<<fae215457d328422253bb5106e62298b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmRole = "Apothecary" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NewsletterFormPart_BrandFragment$data = {
  readonly newsletters: ReadonlyArray<{
    readonly description: string;
    readonly id: string;
    readonly limitToRoles: ReadonlyArray<InfectopharmRole>;
    readonly name: string;
  }>;
  readonly " $fragmentType": "NewsletterFormPart_BrandFragment";
};
export type NewsletterFormPart_BrandFragment$key = {
  readonly " $data"?: NewsletterFormPart_BrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewsletterFormPart_BrandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewsletterFormPart_BrandFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Newsletter",
      "kind": "LinkedField",
      "name": "newsletters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "limitToRoles",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

(node as any).hash = "387e7cdb17b66edce03a5bb4b9badd80";

export default node;
