import { Route, Routes } from "react-router-dom";
import { NoLoginAuthModuleRoutes } from "./NoLoginAuthModuleRoutes";

export const NonAuthNavigation = () => {
	return (
		<Routes>
			{[...NoLoginAuthModuleRoutes].map((prd) => {
				const Component = prd.component as any;

				return <Route key={prd.path} path={prd.path} element={<Component />} />;
			})}
		</Routes>
	);
};
