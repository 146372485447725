/**
 * @generated SignedSource<<b009115420e5c4e97b51dee69e194430>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PharmaceuticalEngineerRoleApplicationForm_BrandFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewsletterFormPart_BrandFragment">;
  readonly " $fragmentType": "PharmaceuticalEngineerRoleApplicationForm_BrandFragment";
};
export type PharmaceuticalEngineerRoleApplicationForm_BrandFragment$key = {
  readonly " $data"?: PharmaceuticalEngineerRoleApplicationForm_BrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PharmaceuticalEngineerRoleApplicationForm_BrandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PharmaceuticalEngineerRoleApplicationForm_BrandFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewsletterFormPart_BrandFragment"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

(node as any).hash = "23c683e7de1ad89fe32505cbe1909ba0";

export default node;
