/**
 * @generated SignedSource<<b0c5935757818939048b81f61bbd2167>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PKARoleApplicationForm_BrandFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewsletterFormPart_BrandFragment">;
  readonly " $fragmentType": "PKARoleApplicationForm_BrandFragment";
};
export type PKARoleApplicationForm_BrandFragment$key = {
  readonly " $data"?: PKARoleApplicationForm_BrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PKARoleApplicationForm_BrandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PKARoleApplicationForm_BrandFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewsletterFormPart_BrandFragment"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

(node as any).hash = "f2001bddc54606af4f7c92382d2fac71";

export default node;
