/**
 * @generated SignedSource<<87f632792260ffe181969514e0ed1734>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LogoutInput = {
  clientMutationId?: string | null;
};
export type LogoutScreen_LogoutMutation$variables = {
  input: LogoutInput;
};
export type LogoutScreen_LogoutMutation$data = {
  readonly InfectopharmAuth: {
    readonly logout: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type LogoutScreen_LogoutMutation = {
  response: LogoutScreen_LogoutMutation$data;
  variables: LogoutScreen_LogoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InfectopharmAuthMutations",
    "kind": "LinkedField",
    "name": "InfectopharmAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "LogoutPayload",
        "kind": "LinkedField",
        "name": "logout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LogoutScreen_LogoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LogoutScreen_LogoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6848a7358f2fb93b9e4f2117ea915089",
    "id": null,
    "metadata": {},
    "name": "LogoutScreen_LogoutMutation",
    "operationKind": "mutation",
    "text": "mutation LogoutScreen_LogoutMutation(\n  $input: LogoutInput!\n) {\n  InfectopharmAuth {\n    logout(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61f521477e3d6cb17a1979be4214ee11";

export default node;
