/**
 * @generated SignedSource<<ef6c7b48c64dc9cfca626267a657cfd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteMyselfButton_UserFragment$data = {
  readonly isLocked: boolean;
  readonly " $fragmentType": "DeleteMyselfButton_UserFragment";
};
export type DeleteMyselfButton_UserFragment$key = {
  readonly " $data"?: DeleteMyselfButton_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteMyselfButton_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteMyselfButton_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "5e49955fad5a0a6cba9f6ad7b8e79abe";

export default node;
