/**
 * @generated SignedSource<<10ef9d00c210ef4039e621d6b105d69c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SupplyEfnScreen_Query$variables = {};
export type SupplyEfnScreen_Query$data = {
  readonly Viewer: {
    readonly InfectopharmAuth: {
      readonly UserInformation: {
        readonly signedUpOverBrand: {
          readonly " $fragmentSpreads": FragmentRefs<"NonAuthScreenBase_BrandFragment">;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"SupplyEfnForm_UserFragment">;
      };
    };
  };
};
export type SupplyEfnScreen_Query = {
  response: SupplyEfnScreen_Query$data;
  variables: SupplyEfnScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SupplyEfnScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InfectopharmAuthViewerSchema",
            "kind": "LinkedField",
            "name": "InfectopharmAuth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserInformation",
                "kind": "LinkedField",
                "name": "UserInformation",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SupplyEfnForm_UserFragment"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "signedUpOverBrand",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NonAuthScreenBase_BrandFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SupplyEfnScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InfectopharmAuthViewerSchema",
            "kind": "LinkedField",
            "name": "InfectopharmAuth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserInformation",
                "kind": "LinkedField",
                "name": "UserInformation",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "efnNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isLocked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleApplicationProcess",
                    "kind": "LinkedField",
                    "name": "roleApplicationProcess",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "targetRole",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Brand",
                    "kind": "LinkedField",
                    "name": "signedUpOverBrand",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "brandColorHex",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "icon",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd0b7b0d279cee2272c4e3961bf4ab03",
    "id": null,
    "metadata": {},
    "name": "SupplyEfnScreen_Query",
    "operationKind": "query",
    "text": "query SupplyEfnScreen_Query {\n  Viewer {\n    InfectopharmAuth {\n      UserInformation {\n        ...SupplyEfnForm_UserFragment\n        signedUpOverBrand {\n          ...NonAuthScreenBase_BrandFragment\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment NonAuthScreenBase_BrandFragment on Brand {\n  name\n  brandColorHex\n  icon {\n    url\n    id\n  }\n}\n\nfragment SupplyEfnForm_UserFragment on UserInformation {\n  id\n  efnNumber\n  isLocked\n  roleApplicationProcess {\n    targetRole\n    address {\n      country\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64c768f7685c40039bdba25f74171c7d";

export default node;
