import { ReactNode, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

interface DialogSettings {
	title: string;
	content: string;
	dialogCallback: DialogCallback;
	affirmativeText?: string;
	negativeText?: string;
}

interface DialogHookProps {
	dialogComponent: ReactNode;
	showDialog: (settings: DialogSettings) => void;
}

type DialogResult = "Accept" | "Deny";

type DialogCallback = (result: DialogResult) => void;

interface DialogState extends DialogSettings {
	dialogCallback: DialogCallback;
	dialogVisible: boolean;
}

const INITIAL_STATE = {
	dialogCallback: () => {},
	title: "Untitled",
	content: "No content",
	affirmativeText: "Ja",
	negativeText: "Nein",
	dialogVisible: false,
};

export const useDialogLogic = (): DialogHookProps => {
	const [dialogState, setDialogState] = useState<DialogState>(INITIAL_STATE);

	return {
		dialogComponent: (
			<Dialog
				className={"mx-8"}
				header={dialogState.title}
				visible={dialogState.dialogVisible}
				footer={
					<div>
						{dialogState.negativeText && (
							<Button
								label={dialogState.negativeText || "Abbrechen"}
								icon="pi pi-times"
								onClick={() => {
									dialogState.dialogCallback("Deny");
									setDialogState(INITIAL_STATE);
								}}
								className="p-button-text"
							/>
						)}
						{dialogState.affirmativeText && (
							<Button
								label={dialogState.affirmativeText || "Akzeptieren"}
								icon="pi pi-check"
								onClick={() => {
									dialogState.dialogCallback("Accept");
									setDialogState(INITIAL_STATE);
								}}
								autoFocus
							/>
						)}
					</div>
				}
				onHide={() => {
					dialogState.dialogCallback("Deny");
					setDialogState(INITIAL_STATE);
				}}
			>
				<div dangerouslySetInnerHTML={{ __html: dialogState.content }} />
			</Dialog>
		),

		showDialog: (settings: DialogSettings) => {
			setDialogState({
				...settings,
				dialogVisible: true,
			});
		},
	};
};
