import { useEffect } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { useTypedDispatch } from "../../../Store";
import { CurrentUserSetter_Query } from "../../../__generated__/CurrentUserSetter_Query.graphql";
import { CurrentUserData, setCurrentUser } from "../../redux/slices/CurrentUserSlice";

const QUERY = graphql`
	query CurrentUserSetter_Query {
		Viewer {
			Auth {
				currentUser {
					permissionsInAccount
				}
			}
		}
	}
`;

interface OwnProps {
	children: any;
}

export const CurrentUserSetter = ({ children }: OwnProps) => {
	const dispatch = useTypedDispatch();

	const currentUserQuery = useLazyLoadQuery<CurrentUserSetter_Query>(QUERY, {});

	useEffect(() => {
		if (currentUserQuery.Viewer.Auth.currentUser) {
			dispatch(setCurrentUser(currentUserQuery.Viewer.Auth.currentUser as CurrentUserData));
		}
		// eslint-disable-next-line
	}, [currentUserQuery]);

	return <>{children}</>;
};
