import React, { useEffect } from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import { useTypedDispatch } from "../../Store";
import { JwtTokenData, setLoggedIn } from "../redux/slices/AuthSlice";
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../components/core/errors/ERROR_MESSAGES";
import { Loader } from "../components/core/components/Loader";

export const LOGIN_WITH_TOKEN_PATH = "/login-with-token/:accountId/:refreshToken";

export const TokenLoginScreen = () => {
	const router = useMatch(LOGIN_WITH_TOKEN_PATH);
	const [params] = useSearchParams();
	const dispatch = useTypedDispatch();

	useEffect(() => {
		if (router?.params?.accountId && router?.params?.refreshToken) {
			fetch(process.env.REACT_APP_API_BASE + "/api/refresh-token", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					refreshToken: router?.params.refreshToken,
					accountId: router?.params?.accountId,
				}),
			}).then((result) => {
				if (result.status === 200) {
					result.text().then((text) => {
						const tokenData: JwtTokenData = JSON.parse(text);

						dispatch(
							setLoggedIn({
								tokenData: tokenData,
							}),
						);

						const redirect = params.get("redirect_url");

						window.location.replace(redirect ? atob(redirect) : "/");
					});
				} else {
					toast.error(
						ERROR_MESSAGES[
							"infectopharmauth_login_with_email_and_password_unspecified_auth"
						],
					);
				}
			});
		}
		// eslint-disable-next-line
	}, []);

	return <Loader />;
};
