import { MiddlewareNextFn, RelayRequestAny } from "react-relay-network-modern";
import { toast } from "react-toastify";
import { ReduxStore } from "../../Store";
import { ERROR_MESSAGES } from "../components/core/errors/ERROR_MESSAGES";
import { logout } from "../redux/slices/AuthSlice";

const LOGOUT_ERRORS = [
	"auth-token-unknown",
	"auth-token-not-set-in-headers",
	"infectopharmauth_token_not_set_in_headers_exception",
	"auth_token_not_set_in_headers_exception",
	"auth_authorisation_exception",
	"auth_token_unknown_exception",
	"auth_account_not_permitted_exception",
	"auth_bearer_token_unknown_exception",
	"auth_authenticate_unspecified_auth",
	"auth_refresh_token_expired",
	"auth_jwt_malformed",
	"auth_account_not_recognized",
	"auth_account_not_found",
	"auth_user_not_found",
	"auth_unknown_permission_kind",
].map((e) => e.toLowerCase());

export const ErrorHandlingMiddleware = (next: MiddlewareNextFn) => async (req: RelayRequestAny) => {
	const res = await next(req);
	const errors = res.errors?.map((e) => e.message?.toLowerCase()) || [];
	const needsToLogout = LOGOUT_ERRORS.find((le) => errors.includes(le));
	if (needsToLogout) {
		ReduxStore.dispatch(logout());
	} else {
		errors?.forEach((e) => {
			const message = ERROR_MESSAGES[e] || e;
			toast.error(message);
		});
	}

	return res;
};
