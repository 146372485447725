import { PrivateRouteDefinition } from "../components/core/model/PrivateRouteDefinition";
import { DashboardScreen } from "../screens/DashboardScreen";

export const DASHBOARD_PATH = "dashboard/:brandId/:redirectBase64?";

export const DashboardModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: DASHBOARD_PATH,
		component: DashboardScreen,
	},
];
