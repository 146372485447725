/**
 * @generated SignedSource<<2a519ecfb3e24e4df0a74f8b07841a7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmRole = "Apothecary" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PersonalAndProfessionalDataStep_BrandFragment$data = {
  readonly availableRoles: ReadonlyArray<InfectopharmRole>;
  readonly brandColorHex: string;
  readonly id: string;
  readonly " $fragmentType": "PersonalAndProfessionalDataStep_BrandFragment";
};
export type PersonalAndProfessionalDataStep_BrandFragment$key = {
  readonly " $data"?: PersonalAndProfessionalDataStep_BrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonalAndProfessionalDataStep_BrandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonalAndProfessionalDataStep_BrandFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableRoles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandColorHex",
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

(node as any).hash = "811e8d4f91cb5cffae2557d7eb59c657";

export default node;
