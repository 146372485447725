import { ValidatedField } from "../../core/components/form/ValidatedField";
import { Checkbox } from "primereact/checkbox";
import React from "react";
import { FormikState } from "formik";
import { InfectopharmRole } from "../../../../__generated__/RolesDisplay_UserStatusFragment.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NewsletterFormPart_BrandFragment$key } from "../../../../__generated__/NewsletterFormPart_BrandFragment.graphql";

const BRAND_FRAGMENT = graphql`
	fragment NewsletterFormPart_BrandFragment on Brand {
		newsletters {
			name
			description
			id
			limitToRoles
		}
	}
`;

export interface NewsletterFormState {
	subscribedNewsletterRefs?: string[];
}

interface OwnProps {
	brandFragmentRef: NewsletterFormPart_BrandFragment$key | null;
	role: InfectopharmRole;
	formik: FormikState<NewsletterFormState> & {
		setFieldTouched: (
			field: string,
			touched?: boolean,
			shouldValidate?: boolean | undefined,
		) => any;
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
	};
}

export const NewsletterFormPart = ({ brandFragmentRef, role, formik }: OwnProps) => {
	const brand = useFragment<NewsletterFormPart_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandFragmentRef,
	);
	return (
		<>
			{brand?.newsletters
				.filter((n) => n.limitToRoles.length === 0 || n.limitToRoles.includes(role))
				.map((newsletter) => {
					return (
						<ValidatedField<NewsletterFormState, string[]>
							key={newsletter.id}
							name={"subscribedNewsletterRefs"}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName }) => {
								const value = fieldValue || [];
								const concatenatedName = fieldName + "-" + newsletter.id;
								return (
									<div className="p-col-12">
										<Checkbox
											id={concatenatedName}
											className="mr-2"
											name={concatenatedName}
											checked={value.includes(newsletter.id)}
											onChange={(e) => {
												const newValue = e.checked
													? [...value, newsletter.id]
													: value.filter((sId) => newsletter.id !== sId);
												updateField(newValue);
											}}
										/>
										<label
											htmlFor={concatenatedName}
											className="p-checkbox-label"
										>
											Ich freue mich den Newsletter{" "}
											<strong>{newsletter.name}</strong> zu abonnieren.{" "}
											<small
												dangerouslySetInnerHTML={{
													__html: newsletter.description,
												}}
											/>
										</label>
									</div>
								);
							}}
						/>
					);
				})}
		</>
	);
};
