/**
 * @generated SignedSource<<0d60b3327eb54f4fbfcceb4834fd2302>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginJwtInput = {
  base64BrandId: string;
  clientMutationId?: string | null;
  email: string;
  password: string;
};
export type LoginScreen_LoginMutation$variables = {
  input: LoginJwtInput;
};
export type LoginScreen_LoginMutation$data = {
  readonly Auth: {
    readonly loginJwt: {
      readonly jwtTokens: {
        readonly accessToken: string;
        readonly refreshToken: string;
      };
    } | null;
  };
};
export type LoginScreen_LoginMutation = {
  response: LoginScreen_LoginMutation$data;
  variables: LoginScreen_LoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "LoginJwtPayload",
        "kind": "LinkedField",
        "name": "loginJwt",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JwtLoginInformation",
            "kind": "LinkedField",
            "name": "jwtTokens",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessToken",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "refreshToken",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginScreen_LoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginScreen_LoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "51322607e39c068f3a9ab93f27bf8745",
    "id": null,
    "metadata": {},
    "name": "LoginScreen_LoginMutation",
    "operationKind": "mutation",
    "text": "mutation LoginScreen_LoginMutation(\n  $input: LoginJwtInput!\n) {\n  Auth {\n    loginJwt(input: $input) {\n      jwtTokens {\n        accessToken\n        refreshToken\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc723b3829c65a27f82c208943b43091";

export default node;
