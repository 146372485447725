import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Newsletters_UserStatusFragment$key } from "../../../__generated__/Newsletters_UserStatusFragment.graphql";
import { Button } from "primereact/button";
import { Newsletters_SubscribeMutation } from "../../../__generated__/Newsletters_SubscribeMutation.graphql";
import { Newsletters_UnsubscribeMutation } from "../../../__generated__/Newsletters_UnsubscribeMutation.graphql";
import styled from "styled-components";
import { useDialogLogic } from "../core/components/dialog/useDialogLogic";
import { toast } from "react-toastify";
import { hasNewsletterTheRegistrationLocation } from "../signup/steps/NewsletterDataStep";
import { hasNewsletterTheSignupProcessCountry } from "../signup/steps/NewsletterDataStep";
import { Newsletter } from "../signup/model/Newsletter";
import { InfectopharmRole } from "../../../__generated__/SupplyEfnForm_UserFragment.graphql";

const USER_STATUS_FRAGMENT = graphql`
	fragment Newsletters_UserStatusFragment on UserInformation {
		roleApplicationProcess {
			address {
				country
			}
			targetRole
		}
		signedUpOverBrand {
			newsletters {
				id
				name
				description
				registrationDisplayLocations
				countries
				limitToRoles
			}
		}
		signedUpToNewsletters {
			id
			name
		}
	}
`;

const SUBSCRIBE_MUTATION = graphql`
	mutation Newsletters_SubscribeMutation($input: NewsletterSubscriptionInput!) {
		Newsletter {
			subscribeToNewsletter(input: $input) {
				userInformation {
					...DashboardScreen_UserStatusFragment
				}
			}
		}
	}
`;

const UNSUBSCRIBE_MUTATION = graphql`
	mutation Newsletters_UnsubscribeMutation($input: NewsletterUnsubscriptionInput!) {
		Newsletter {
			unsubscribeFromNewsletter(input: $input) {
				userInformation {
					...DashboardScreen_UserStatusFragment
				}
			}
		}
	}
`;

interface OwnProps {
	userStatusFragmentRef: Newsletters_UserStatusFragment$key;
}

export const Newsletters = ({ userStatusFragmentRef }: OwnProps) => {
	const user = useFragment<Newsletters_UserStatusFragment$key>(
		USER_STATUS_FRAGMENT,
		userStatusFragmentRef,
	);
	const [subscribe, isSubscribing] =
		useMutation<Newsletters_SubscribeMutation>(SUBSCRIBE_MUTATION);
	const [unsubscribe, isUnsubscribing] =
		useMutation<Newsletters_UnsubscribeMutation>(UNSUBSCRIBE_MUTATION);

	const { dialogComponent, showDialog } = useDialogLogic();

	if (!user.signedUpOverBrand?.newsletters) {
		return null;
	}
	const hasNewsletterUserRole = (
		newsletter: Newsletter,
		targetRole: InfectopharmRole,
	): boolean => {
		return newsletter?.limitToRoles!.includes(targetRole);
	};

	const hasNewslettersToShow = user.signedUpOverBrand?.newsletters.map((newsletter) => {
		const isSignedUp = user.signedUpToNewsletters.map((nl) => nl.id).includes(newsletter.id);
		const hasNewsletterWithProfileLocation = hasNewsletterTheRegistrationLocation(
			newsletter,
			"Profile",
		);
		const hasNewsletterTheProfileCountry = hasNewsletterTheSignupProcessCountry(
			newsletter,
			user.roleApplicationProcess?.address.country!,
		);
		const hasNewsletterTheUserTargetRole = hasNewsletterUserRole(
			newsletter,
			user.roleApplicationProcess?.targetRole!,
		);
		return (
			isSignedUp ||
			(hasNewsletterWithProfileLocation &&
				hasNewsletterTheProfileCountry &&
				hasNewsletterTheUserTargetRole)
		);
	});

	return (
		<div>
			{dialogComponent}
			{hasNewslettersToShow.includes(true) && <h2>Newsletter</h2>}
			<div className="flex flex-column">
				{user.signedUpOverBrand?.newsletters.map((newsletter) => {
					const isSignedUp = user.signedUpToNewsletters
						.map((nl) => nl.id)
						.includes(newsletter.id);
					return (
						<div key={newsletter.id}>
							{((hasNewsletterTheRegistrationLocation(newsletter, "Profile") &&
								hasNewsletterTheSignupProcessCountry(
									newsletter,
									user.roleApplicationProcess?.address.country!,
								) &&
								hasNewsletterUserRole(
									newsletter,
									user.roleApplicationProcess?.targetRole!,
								)) ||
								isSignedUp) && (
								<NewsletterCard className="flex" key={newsletter.id}>
									<div className="flex-shrink-1">
										<div>
											{newsletter.name}{" "}
											{isSignedUp ? " (Angemeldet)" : " (nicht angemeldet)"}
										</div>
										<NewsletterDescription
											dangerouslySetInnerHTML={{
												__html: newsletter.description,
											}}
										/>
									</div>
									<div className="ml-auto">
										{isSignedUp ? (
											<Button
												className="ml-auto"
												disabled={isSubscribing || isUnsubscribing}
												onClick={() =>
													showDialog({
														title: "Abmelden",
														affirmativeText: "Abmelden",
														negativeText: "Abbrechen",
														content: `Möchten Sie sich wirklich vom Newsletter ${newsletter.name} abmelden?`,
														dialogCallback: (result) => {
															if (result === "Accept") {
																unsubscribe({
																	variables: {
																		input: {
																			newsletterRef:
																				newsletter.id,
																		},
																	},
																	onCompleted: () => {
																		toast.success(
																			"Sie haben sich erfolgreich vom Newsletter abgemeldet.",
																		);
																	},
																});
															}
														},
													})
												}
												label={"Abmelden"}
											/>
										) : (
											<Button
												className="ml-auto"
												disabled={isSubscribing || isUnsubscribing}
												onClick={() => {
													subscribe({
														variables: {
															input: { newsletterRef: newsletter.id },
														},
														onCompleted: () => {
															toast.success(
																"Sie haben sich erfolgreich zum Newsletter angemeldet.",
															);
														},
													});
												}}
												label={"Anmelden"}
											/>
										)}
									</div>
								</NewsletterCard>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

const NewsletterCard = styled.div`
	display: flex;
	align-items: center;

	border-radius: 8px;
	box-shadow: 0 0 50px 0 rgb(0 0 0 / 5%);
	padding: 25px;
	background-color: white;
	margin-bottom: 25px;
`;

const NewsletterDescription = styled.div`
	p {
		font-size: 12px;
		margin: 0;
	}
`;
