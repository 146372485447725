import React from "react";
import { Message } from "primereact/message";
import { graphql } from "babel-plugin-relay/macro";
import { Disposable, useMutation, UseMutationConfig } from "react-relay";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import styled from "styled-components";
import { NeedsEmailValidationStep_ActivationMutation } from "../../../__generated__/NeedsEmailValidationStep_ActivationMutation.graphql";

const ACTIVATION_EMAIL_RESEND = graphql`
	mutation ResendEmailValidation_ActivationMutation($input: ResendActivationMutationInput!) {
		InfectopharmAuth {
			userResendActivationEmail(input: $input) {
				clientMutationId
			}
		}
	}
`;

const ResendActivationButton = styled(Button)`
	background-color: transparent !important;
	:hover {
		background-color: transparent !important;
	}
`;

const MessageContent = (props: {
	signupProcessId: string;
	resendActivationEmail: (
		config: UseMutationConfig<NeedsEmailValidationStep_ActivationMutation>,
	) => Disposable;
	isResending: boolean;
	brandId: string;
}) => {
	return (
		<p>
			Der Aktivierungslink ist abgelaufen.
			<br />
			Bitte fordern Sie einen neuen Link an.{" "}
			<ResendActivationButton
				label={"Bestätigungs-Email erneut senden"}
				disabled={props.isResending}
				className="p-button-text p-0"
				onClick={() =>
					props.resendActivationEmail({
						variables: {
							input: {
								signUpProcessId: props.signupProcessId,
								brandId: props.brandId,
							},
						},
						onCompleted: () => {
							toast("Bestätigungs-Email wurde erfolgreich versendet", {
								type: "success",
							});
						},
					})
				}
			/>
		</p>
	);
};

export const ResendEmailValidation = (props: { signupProcessId: string; brandId: string }) => {
	const [resendActivationEmail, isResending] =
		useMutation<NeedsEmailValidationStep_ActivationMutation>(ACTIVATION_EMAIL_RESEND);

	return (
		<div>
			<Message
				severity={"success"}
				content={
					<MessageContent
						signupProcessId={props.signupProcessId}
						resendActivationEmail={resendActivationEmail}
						isResending={isResending}
						brandId={props.brandId}
					/>
				}
			/>
		</div>
	);
};
