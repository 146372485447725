import React from "react";
import MaintenanceBg from "../assets/bg-maintenance.jpg";
import MaintenanceBgSmall from "../assets/bg-maintenance-smallscreen.png";
import { CardContainer, DescriptionContainer, PageContainer } from "./MaintenanceScreen";
import graphql from "babel-plugin-relay/macro";
import { useParams } from "react-router-dom";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { UserHasNoAuthorisationScreen_Brand_Query } from "../../__generated__/UserHasNoAuthorisationScreen_Brand_Query.graphql";
import { BrandIcon } from "../components/core/components/brand/BrandIcon";
import { UserHasNoAuthorisationScreen_BrandFragment$key } from "../../__generated__/UserHasNoAuthorisationScreen_BrandFragment.graphql";
import styled from "styled-components";
import { Button } from "primereact/button";

const BRAND_QUERY = graphql`
	query UserHasNoAuthorisationScreen_Brand_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Brand {
				id
				brandColorHex
				brandUrl
				icon {
					url
					name
				}
				...UserHasNoAuthorisationScreen_BrandFragment
			}
		}
	}
`;
const BRAND_FRAGMENT = graphql`
	fragment UserHasNoAuthorisationScreen_BrandFragment on Brand {
		id
		brandColorHex
		brandUrl
		icon {
			url
			name
		}
	}
`;
export const UserHasNoAuthorisationScreen = () => {
	const params = useParams<{ brandId: string; redirectBase64?: string }>();
	const brandIdParam = params.brandId ?? process.env.REACT_APP_WISSENWIRKT_ID;

	const brandQuery = useLazyLoadQuery<UserHasNoAuthorisationScreen_Brand_Query>(BRAND_QUERY, {
		id: brandIdParam || "",
		skip: !brandIdParam,
	});
	const brandPlatform = useFragment<UserHasNoAuthorisationScreen_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandQuery.node!,
	);
	const redirect = params.redirectBase64;

	const deviceWidth = window.innerWidth;
	const websiteUrl = brandPlatform.brandUrl || "https://www.infectopharm.com";
	return (
		<PageContainer imageurl={deviceWidth > 991 ? MaintenanceBg : MaintenanceBgSmall}>
			<CardContainer className="flex-column align-items-center">
				<CardMaintenance>
					{brandPlatform.icon?.url && (
						<ImageContainer width={120}>
							<BrandIcon
								className="w-full"
								alt={brandPlatform?.icon?.name}
								src={brandPlatform?.icon?.url}
							/>
						</ImageContainer>
					)}
					<DescriptionContainer>
						<h3 className="mt-4 mb-0 text-center">
							Sie haben leider keine Berechtigung, den Inhalt dieser Seite zu sehen.
						</h3>
					</DescriptionContainer>
				</CardMaintenance>
				<GoToWebsiteButton
					color={brandPlatform.brandColorHex}
					label={"Zurück zur Webseite"}
					className="mt-4"
					onClick={() => {
						window.location.replace(redirect ? atob(redirect) : websiteUrl);
					}}
				/>
			</CardContainer>
		</PageContainer>
	);
};
interface ImageContainerProps {
	width: number;
}
const ImageContainer = styled.div<ImageContainerProps>`
	width: ${(props) => props.width}px;
	background-color: #ffffff;
	height: auto;
	padding: 10px;
`;
const CardMaintenance = styled.div`
	background-color: #545e60;
	width: 550px;
	height: 180px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;
	margin: 10px;
	@media screen and (max-width: 991px) {
		width: 90%;
		height: auto;
	}
`;
interface ButtonProps {
	color: string;
}
const GoToWebsiteButton = styled(Button)<ButtonProps>`
	background-color: ${(props) => props.color} !important;
	border: 1px solid ${(props) => props.color} !important;
`;
