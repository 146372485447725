/**
 * @generated SignedSource<<0c221bf131db88ade2d2644316e39769>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthScreenBase_BrandFragment$data = {
  readonly brandColorHex: string;
  readonly brandUrl: string;
  readonly icon: {
    readonly name: string;
    readonly url: string | null;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "AuthScreenBase_BrandFragment";
};
export type AuthScreenBase_BrandFragment$key = {
  readonly " $data"?: AuthScreenBase_BrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthScreenBase_BrandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthScreenBase_BrandFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandColorHex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

(node as any).hash = "f1957603243e67781c001570cbda7706";

export default node;
