/**
 * @generated SignedSource<<e3df981f938f60088efd85da522e6801>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmRole = "Apothecary" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuthScreenBase_UserInformationFragment$data = {
  readonly id: string;
  readonly roleApplicationProcess: {
    readonly targetRole: InfectopharmRole;
  } | null;
  readonly signedUpOverBrand: {
    readonly brandColorHex: string;
    readonly brandUrl: string;
    readonly icon: {
      readonly url: string | null;
    } | null;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"NonAuthScreenBase_BrandFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"RoleUnlockProcess_UserInformationFragment">;
  readonly " $fragmentType": "AuthScreenBase_UserInformationFragment";
};
export type AuthScreenBase_UserInformationFragment$key = {
  readonly " $data"?: AuthScreenBase_UserInformationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthScreenBase_UserInformationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthScreenBase_UserInformationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleApplicationProcess",
      "kind": "LinkedField",
      "name": "roleApplicationProcess",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "targetRole",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "signedUpOverBrand",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandColorHex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "icon",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NonAuthScreenBase_BrandFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RoleUnlockProcess_UserInformationFragment"
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "56bcadfa8e11ad65b913b7af97782e0e";

export default node;
