import { useFormik } from "formik";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { DoctorRoleApplicationForm_ApplyRoleMutation } from "../../../../__generated__/DoctorRoleApplicationForm_ApplyRoleMutation.graphql";
import { Button } from "primereact/button";
import { DefaultTextFieldComponent } from "../../core/components/form/DefaultTextInput";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { AddressFormPart, AddressFormState } from "./AddressFormPart";
import { ContactDetailsFormPart, ContactDetailsFormState } from "./ContactDetailsFormPart";
import { NewsletterFormPart, NewsletterFormState } from "./NewsletterFormPart";
import { DoctorRoleApplicationForm_BrandFragment$key } from "../../../../__generated__/DoctorRoleApplicationForm_BrandFragment.graphql";
import { AddressValidationHelper } from "../../signup/helpers/countryRelatedHelpers";

const BRAND_FRAGMENT = graphql`
	fragment DoctorRoleApplicationForm_BrandFragment on Brand {
		...NewsletterFormPart_BrandFragment
	}
`;

type FormState = ContactDetailsFormState &
	AddressFormState &
	NewsletterFormState & {
		efnNumber?: string;
		fachrichtung?: string;
	};

const APPLY_APOTHECARY_MUTATION = graphql`
	mutation DoctorRoleApplicationForm_ApplyRoleMutation($input: ApplyForDoctorRoleInput!) {
		Roles {
			applyForDoctorRole(input: $input) {
				userStatus {
					signedUpOverBrand {
						brandUrl
					}
					...DashboardScreen_UserStatusFragment
				}
			}
		}
	}
`;

interface OwnProps {
	brandFragmentRef: DoctorRoleApplicationForm_BrandFragment$key | null;
	applicationToken?: string;
	onFinished?: (brandUrl?: string) => void;
}

export const DoctorRoleApplicationForm = ({
	brandFragmentRef,
	applicationToken,
	onFinished,
}: OwnProps) => {
	const [applyForRole, isApplyingForRole] =
		useMutation<DoctorRoleApplicationForm_ApplyRoleMutation>(APPLY_APOTHECARY_MUTATION);
	const brand = useFragment<DoctorRoleApplicationForm_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandFragmentRef,
	);

	const formik = useFormik<FormState>({
		initialValues: {
			company: "",
			phone: "",
			fax: "",
			efnNumber: "",
			street: "",
			postalCode: "",
			city: "",
			country: "Deutschland",
			fachrichtung: "",
			subscribedNewsletterRefs: [],
		},
		validationSchema: Yup.object().shape({
			...AddressValidationHelper,
			fachrichtung: Yup.string().required("Fachrichtung wird benötigt"),
		}),
		onSubmit: (values) => {
			applyForRole({
				variables: {
					input: {
						applicationToken: applicationToken,
						efnNumber: values.efnNumber,
						contactDetails: {
							faxNumber: values.fax,
							phoneNumber: values.phone,
						},
						practiceAddress: {
							company: values.company,
							street: values.street!,
							postalCode: values.postalCode!,
							city: values.city!,
							country: values.country!,
						},
						fachrichtung: values.fachrichtung,
						subscribedNewsletterRefs: [...(values.subscribedNewsletterRefs || [])],
					},
				},
				onCompleted: (data) => {
					toast("Rolle erfolgreich beantragt.", { type: "success" });
					if (onFinished)
						onFinished(
							data.Roles.applyForDoctorRole?.userStatus.signedUpOverBrand?.brandUrl,
						);
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"efnNumber"}
				label={`${
					formik.values.country == "Oesterreich"
						? "Arztnummer"
						: "Einheitliche Fortbildungs-Nummer (EFN)"
				}`}
				iconClass={"pi-user"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<FormState, string>
				name={"fachrichtung"}
				label={"Fachrichtung"}
				iconClass={"pi-search-plus"}
				formikConfig={formik}
				required={true}
				component={DefaultTextFieldComponent}
			/>
			<ContactDetailsFormPart formik={formik} />
			<AddressFormPart formik={formik} />
			<NewsletterFormPart brandFragmentRef={brand} role={"Doctor"} formik={formik} />

			<Button
				disabled={isApplyingForRole}
				type="submit"
				label="Registrierung abschließen"
				className="mt-2 mb-2"
			/>
		</form>
	);
};
