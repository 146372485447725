import { graphql } from "babel-plugin-relay/macro";
import { NonAuthScreenBase } from "../components/core/components/base/NonAuthScreenBase";
import { SupplyEfnForm } from "../components/dashboard/SupplyEfnForm";
import { useLazyLoadQuery } from "react-relay";
import { SupplyEfnScreen_Query } from "../../__generated__/SupplyEfnScreen_Query.graphql";
import { Button } from "primereact/button";

const QUERY = graphql`
	query SupplyEfnScreen_Query {
		Viewer {
			InfectopharmAuth {
				UserInformation {
					...SupplyEfnForm_UserFragment
					signedUpOverBrand {
						...NonAuthScreenBase_BrandFragment
					}
				}
			}
		}
	}
`;

export const SupplyEfnScreen = () => {
	const query = useLazyLoadQuery<SupplyEfnScreen_Query>(QUERY, {});

	return (
		<NonAuthScreenBase
			brandFragmentRef={query.Viewer.InfectopharmAuth.UserInformation.signedUpOverBrand}
		>
			<SupplyEfnForm
				onSuccess={() => {
					window.location.href = "https://cme-portal.infectopharm.com/";
				}}
				userFragmentRef={query.Viewer.InfectopharmAuth.UserInformation}
			>
				<Button
					className="mt-5 w-12"
					label="Zurück zum CME-Portal"
					onClick={() => {
						window.location.href = "https://cme-portal.infectopharm.com/";
					}}
				/>
			</SupplyEfnForm>
		</NonAuthScreenBase>
	);
};
