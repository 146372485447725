/**
 * @generated SignedSource<<a842d0bb7c46cc7c37868711c5fe946b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApothecaryRoleApplicationForm_BrandFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewsletterFormPart_BrandFragment">;
  readonly " $fragmentType": "ApothecaryRoleApplicationForm_BrandFragment";
};
export type ApothecaryRoleApplicationForm_BrandFragment$key = {
  readonly " $data"?: ApothecaryRoleApplicationForm_BrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApothecaryRoleApplicationForm_BrandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApothecaryRoleApplicationForm_BrandFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewsletterFormPart_BrandFragment"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

(node as any).hash = "7f43c222ddf2f9785449adbc80000b6c";

export default node;
