/**
 * @generated SignedSource<<757a2c51b1f84493159e2e8058cb9059>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignupProcessStatus = "Finished" | "NeedsEmailValidation" | "NeedsNewsletterSignup" | "NeedsPersonalAndProfessionalData" | "%future added value";
export type ProvideNewsletterSignupsInput = {
  brandId: string;
  clientMutationId?: string | null;
  id: string;
  newslettersRef: ReadonlyArray<string>;
};
export type NewsletterDataStep_ProvidePersonalDataMutation$variables = {
  input: ProvideNewsletterSignupsInput;
};
export type NewsletterDataStep_ProvidePersonalDataMutation$data = {
  readonly Signup: {
    readonly provideNewsletterSignups: {
      readonly signupProcess: {
        readonly id: string;
        readonly status: SignupProcessStatus;
      };
    } | null;
  };
};
export type NewsletterDataStep_ProvidePersonalDataMutation = {
  response: NewsletterDataStep_ProvidePersonalDataMutation$data;
  variables: NewsletterDataStep_ProvidePersonalDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SignupMutations",
    "kind": "LinkedField",
    "name": "Signup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ProvideNewsletterSignupsPayload",
        "kind": "LinkedField",
        "name": "provideNewsletterSignups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SignupProcess",
            "kind": "LinkedField",
            "name": "signupProcess",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsletterDataStep_ProvidePersonalDataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewsletterDataStep_ProvidePersonalDataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "573e1a22261839397956ddb1f1eced7d",
    "id": null,
    "metadata": {},
    "name": "NewsletterDataStep_ProvidePersonalDataMutation",
    "operationKind": "mutation",
    "text": "mutation NewsletterDataStep_ProvidePersonalDataMutation(\n  $input: ProvideNewsletterSignupsInput!\n) {\n  Signup {\n    provideNewsletterSignups(input: $input) {\n      signupProcess {\n        id\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d12baded4f8c2f039c99f950eca5b89c";

export default node;
