import { NonAuthScreenBase } from "../core/components/base/NonAuthScreenBase";
import { NotLoggedInLoginForm } from "../login/NotLoggedInLoginForm";
import { useNavigate, useParams } from "react-router-dom";
import { NotLoggedInPlaceholder_Query } from "../../../__generated__/NotLoggedInPlaceholder_Query.graphql";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useEffect } from "react";

export const NotLoggedInPlaceholder = () => {
	const navigate = useNavigate();
	const params = useParams<{ brandId: string; redirectBase64?: string }>();
	const isLoggingOut = params.brandId?.includes("logout");

	const data = useLazyLoadQuery<NotLoggedInPlaceholder_Query>(
		graphql`
			query NotLoggedInPlaceholder_Query($id: ID!, $skip: Boolean!) {
				node(id: $id) @skip(if: $skip) {
					... on Brand {
						id
						name
						brandColorHex
						icon {
							url
						}
						...NonAuthScreenBase_BrandFragment
						...NotLoggedInLoginForm_BrandFragment
					}
				}
			}
		`,
		{
			id: params.brandId!
				? isLoggingOut
					? (params.redirectBase64 as string)
					: params.brandId
				: "QnJhbmQ6QnJhbmQ6MWVkZDQ2YzYtODkwMC00YTI4LTk1MTEtNTllYzgyNzVhNDg0",
			skip: params.brandId ? !params.brandId : false,
		},
	);

	if (!data.node) {
		return null;
	}

	useEffect(() => {
		if (isLoggingOut) {
			navigate(`/${params.redirectBase64}`);
		}
	}, []);

	return (
		<NonAuthScreenBase brandFragmentRef={data.node}>
			<h1>{data.node.name} Identitätsverwaltung</h1>
			<NotLoggedInLoginForm brandFragmentRef={data.node} />
		</NonAuthScreenBase>
	);
};
