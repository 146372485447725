import React from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ValidatedField } from "../components/core/components/form/ValidatedField";
import * as Yup from "yup";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { useTypedDispatch } from "../../Store";
import { JwtTokenData, setLoggedIn } from "../redux/slices/AuthSlice";
import { LoginScreen_LoginMutation } from "../../__generated__/LoginScreen_LoginMutation.graphql";

const LOGIN_MUTATION = graphql`
	mutation LoginScreen_LoginMutation($input: LoginJwtInput!) {
		Auth {
			loginJwt(input: $input) {
				jwtTokens {
					accessToken
					refreshToken
				}
			}
		}
	}
`;

interface FormState {
	email: string;
	password: string;
}

export const LoginScreen = () => {
	const [login, isLoggingIn] = useMutation<LoginScreen_LoginMutation>(LOGIN_MUTATION);
	const dispatch = useTypedDispatch();
	const navigate = useNavigate();

	const formik = useFormik<FormState>({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt."),
			password: Yup.string()
				.min(8, "Ein Passwort muss mindestens 8 Stellen lang sein.")
				.required("Das Feld Passwort wird benötigt."),
		}),
		onSubmit: (data) => {
			login({
				variables: {
					input: {
						email: data.email,
						password: data.password,
						base64BrandId: "",
					},
				},
				onCompleted: (response) => {
					if (response.Auth.loginJwt?.jwtTokens) {
						dispatch(
							setLoggedIn({
								tokenData: response.Auth.loginJwt?.jwtTokens as JwtTokenData,
							}),
						);

						navigate("/");
					}
				},
				onError: () => {
					// toast.error("Login was unsuccessful. Please check your email and password.");
				},
			});
		},
	});

	return (
		<div className="flex justify-content-center align-items-center p-sidebar-full">
			<Card>
				<h1 className="text-center">Infectopharm Test Login</h1>
				<p>
					Dieses Login ist nur zu Demozwecken. Das eigentliche Login findet auf den
					Wordpress-Seiten statt.
				</p>
				<FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<FormState, string>
						name={"email"}
						label={"E-Mail"}
						iconClass={"pi-envelope"}
						formikConfig={formik}
						component={({ fieldValue, updateField, fieldName, isValid }) => {
							return (
								<InputText
									id={fieldName}
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									className={classNames({ "p-invalid": !isValid })}
								/>
							);
						}}
					/>
					<ValidatedField<FormState, string>
						name={"password"}
						label={"Passwort"}
						formikConfig={formik}
						component={({ fieldValue, updateField, fieldName, isValid }) => {
							return (
								<Password
									id={fieldName}
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									toggleMask
									feedback={false}
									className={classNames({ "p-invalid": !isValid })}
								/>
							);
						}}
					/>
					<Button
						disabled={isLoggingIn}
						type="submit"
						label="Einloggen"
						className="mt-2 mb-2"
					/>
				</FormContainer>
			</Card>
		</div>
	);
};

const FormContainer = styled.form`
	min-width: 400px;
`;
