import RegistrationImage from "../../../../assets/registration-image.jpg";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NonAuthScreenBase_BrandFragment$key } from "../../../../../__generated__/NonAuthScreenBase_BrandFragment.graphql";
import styled from "styled-components";
import { BrandedContainer } from "../brand/BrandedContainer";
import { BrandIcon } from "../brand/BrandIcon";

const BRAND_FRAGMENT = graphql`
	fragment NonAuthScreenBase_BrandFragment on Brand {
		name
		brandColorHex
		icon {
			url
		}
	}
`;

interface OwnProps {
	brandFragmentRef?: NonAuthScreenBase_BrandFragment$key | null;
	children: any;
}

export const NonAuthScreenBase = ({ brandFragmentRef, children }: OwnProps) => {
	const brand = useFragment<NonAuthScreenBase_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandFragmentRef || null,
	);

	return (
		<BrandedContainer brandColor={brand?.brandColorHex} className="flex flex-grow-1">
			<Sidebar className="p-5">
				<div className="mb-8">
					{brand?.icon?.url ? (
						<BrandIcon alt={brand?.name} src={brand.icon?.url} />
					) : null}
				</div>
				<div className="p-component">{children}</div>
			</Sidebar>
			<div
				className="flex-grow-1 bg-no-repeat bg-center bg-cover"
				style={{ backgroundImage: `url(${RegistrationImage})` }}
			></div>
		</BrandedContainer>
	);
};

const Sidebar = styled.div`
	max-width: 430px;
	width: 100%;
`;
