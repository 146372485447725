import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { NonAuthScreenBase } from "../components/core/components/base/NonAuthScreenBase";
import { REGISTRATION_PATH } from "../routes/NoLoginAuthModuleRoutes";
import { SignupProcessScreen_Query } from "../../__generated__/SignupProcessScreen_Query.graphql";
import { SignupProcessStatus } from "../../__generated__/StartSignupStep_CheckEmailMutation.graphql";
import { StartSignupStep } from "../components/signup/steps/StartSignupStep";
import { Newsletter } from "../components/signup/model/Newsletter";
import { NewsletterDataStep } from "../components/signup/steps/NewsletterDataStep";
import { NeedsEmailValidationStep } from "../components/signup/steps/NeedsEmailValidationStep";
import { PersonalAndProfessionalDataStep } from "../components/signup/steps/PersonalAndProfessionalDataStep";

interface State {
	step?: SignupProcessStatus;
	id?: string;
	newsletters?: ReadonlyArray<Newsletter>;
}

export const SignupProcessScreen = () => {
	const router = useMatch(REGISTRATION_PATH);
	const [state, setState] = useState<State>({
		step: undefined,
	});

	const data = useLazyLoadQuery<SignupProcessScreen_Query>(
		graphql`
			query SignupProcessScreen_Query($id: ID!, $skip: Boolean!) {
				node(id: $id) @skip(if: $skip) {
					... on Brand {
						...NonAuthScreenBase_BrandFragment
						...StartSignupStep_BrandFragment
						...PersonalAndProfessionalDataStep_BrandFragment
						...NewsletterDataStep_BrandFragment
						...NeedsEmailValidationStep_BrandFragment
						newsletters {
							id
							name
							description
							registrationDisplayLocations
							countries
						}
					}
				}
			}
		`,
		{ id: router?.params.brandId!, skip: !router?.params.brandId },
	);

	if (!data.node) {
		return null;
	}
	return (
		<NonAuthScreenBase brandFragmentRef={data.node}>
			{state.step === undefined && (
				<StartSignupStep
					brandFragment={data.node!}
					advance={(newStatus, id) => setState({ ...state, step: newStatus, id: id })}
				/>
			)}
			{state.step === "NeedsPersonalAndProfessionalData" && (
				<PersonalAndProfessionalDataStep
					brandFragmentRef={data.node!}
					signupProcessId={state.id!}
					advance={(newStatus, newsletters) =>
						setState({ ...state, step: newStatus, newsletters: newsletters })
					}
				/>
			)}
			{state.step === "NeedsNewsletterSignup" && (
				<NewsletterDataStep
					signupProcessId={state.id!}
					newsletters={state.newsletters || data.node.newsletters || []}
					advance={(newStatus) => setState({ ...state, step: newStatus })}
					brandFragmentRef={data.node!}
				/>
			)}
			{state.step === "NeedsEmailValidation" && (
				<NeedsEmailValidationStep
					brandFragmentRef={data.node!}
					signupProcessId={state.id!}
				/>
			)}
			{state.step === "Finished" && (
				<StartSignupStep
					brandFragment={data.node!}
					advance={(newStatus, id) => setState({ ...state, step: newStatus, id: id })}
					stateStep={state.step}
				/>
			)}
		</NonAuthScreenBase>
	);
};
