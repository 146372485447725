import React, { ReactNode } from "react";
import { CurrentUserSetter } from "./CurrentUserSetter";
import { useTypedSelector } from "../../../Store";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { AuthenticationFallback_Query } from "../../../__generated__/AuthenticationFallback_Query.graphql";
import { MaintenanceScreen } from "../../screens/MaintenanceScreen";
import { selectAuthSlice } from "../../redux/slices/AuthSlice";

const QUERY = graphql`
	query AuthenticationFallback_Query {
		Maintenance {
			MaintenanceUpdate {
				id
				isUpdating
			}
		}
	}
`;
interface OwnProps {
	loginFallback: ReactNode;
	children: any;
}

export function AuthenticationFallback({ children, loginFallback }: OwnProps) {
	const authState = useTypedSelector(selectAuthSlice);
	const data = useLazyLoadQuery<AuthenticationFallback_Query>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);
	const isUpdating = data.Maintenance.MaintenanceUpdate.isUpdating;

	return (
		<>
			{isUpdating ? (
				<MaintenanceScreen />
			) : authState.isLoggedIn ? (
				<CurrentUserSetter>{children}</CurrentUserSetter>
			) : (
				loginFallback
			)}
		</>
	);
}
