/**
 * @generated SignedSource<<5c5bb23af26ac1137a2da6a40d2dfafa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeEmailForm_UserFragment$data = {
  readonly isLocked: boolean;
  readonly " $fragmentType": "ChangeEmailForm_UserFragment";
};
export type ChangeEmailForm_UserFragment$key = {
  readonly " $data"?: ChangeEmailForm_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeEmailForm_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeEmailForm_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "7058bea753caada0162aedf1cabe5dc8";

export default node;
