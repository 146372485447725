import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { RolesDisplay_UserStatusFragment$key } from "../../../__generated__/RolesDisplay_UserStatusFragment.graphql";
import { Card } from "primereact/card";
import { ApplicationProcessStatusTranslations } from "../roles/model/Translations";
import { useState } from "react";
import { Button } from "primereact/button";
import { EditRoleForm } from "./EditRoleForm";
import { Message } from "primereact/message";

const USER_STATUS_FRAGMENT = graphql`
	fragment RolesDisplay_UserStatusFragment on UserInformation {
		id
		email
		isLocked
		roleApplicationProcess {
			status
			userStatus
			targetRole
			address {
				company
				street
				postalCode
				city
			}
			contactDetails {
				faxNumber
				phoneNumber
			}
		}
		...EditRoleForm_UserInformationFragment
	}
`;

interface OwnProps {
	userStatusFragmentRef: RolesDisplay_UserStatusFragment$key;
}

export const RolesDisplay = ({ userStatusFragmentRef }: OwnProps) => {
	const [isEditing, setEditing] = useState<boolean>(false);
	const userStatus = useFragment<RolesDisplay_UserStatusFragment$key>(
		USER_STATUS_FRAGMENT,
		userStatusFragmentRef,
	);

	return (
		<div>
			<div key={userStatus.id}>
				{userStatus.roleApplicationProcess?.status !== "Finished" && (
					<Message
						className="flex mb-4"
						severity="warn"
						text="Der Freischaltungs-Prozess ist noch nicht abgeschlossen, deshalb können Sie noch nicht auf alle Daten zugreifen."
					/>
				)}

				{userStatus.roleApplicationProcess?.status === "Finished" &&
					userStatus.roleApplicationProcess?.userStatus === "Inactive" && (
						<Message
							className="flex mb-4"
							severity="warn"
							text="Ihr Konto ist derzeit inaktiv, deshalb können Sie noch nicht auf alle Daten zugreifen."
						/>
					)}

				<Card
					key={userStatus.id}
					className="mb-4"
					header={
						<div className="p-2">
							<h2 className="flex align-items-center m-0">
								<span>Status</span>
								<span className="ml-auto">
									{
										ApplicationProcessStatusTranslations[
											userStatus.roleApplicationProcess?.status || ""
										]
									}
								</span>
							</h2>
						</div>
					}
				>
					{isEditing ? (
						<>
							<EditRoleForm
								onClose={() => {
									setEditing(false);
								}}
								userInformationFragmentRef={userStatus}
							/>
						</>
					) : (
						<>
							<h3>Kontaktdaten</h3>
							<div className="p-fluid">
								<div className="field grid">
									<label className="col-fixed font-bold" style={{ width: 150 }}>
										E-Mail
									</label>
									<div className="col">{userStatus.email}</div>
								</div>
								<div className="field grid">
									<label className="col-fixed font-bold" style={{ width: 150 }}>
										Telefonnummer
									</label>
									<div className="col">
										{
											userStatus.roleApplicationProcess?.contactDetails
												?.phoneNumber
										}
									</div>
								</div>
								<div className="field grid">
									<label className="col-fixed font-bold" style={{ width: 150 }}>
										Faxnummer
									</label>
									<div className="col">
										{
											userStatus.roleApplicationProcess?.contactDetails
												?.faxNumber
										}
									</div>
								</div>
							</div>
							<h3>Dienstliche Anschrift</h3>
							<div className="p-fluid">
								<div className="field grid">
									<label className="col-fixed font-bold" style={{ width: 150 }}>
										Name der Praxis
									</label>
									<div className="col">
										{userStatus.roleApplicationProcess?.address?.company}
									</div>
								</div>
								<div className="field grid">
									<label className="col-fixed font-bold" style={{ width: 150 }}>
										Straße und Hausnummer
									</label>
									<div className="col">
										{userStatus.roleApplicationProcess?.address?.street}
									</div>
								</div>
								<div className="field grid">
									<label className="col-fixed font-bold" style={{ width: 150 }}>
										PLZ und Ort
									</label>
									<div className="col">
										{userStatus.roleApplicationProcess?.address?.postalCode}{" "}
										{userStatus.roleApplicationProcess?.address?.city}
									</div>
								</div>
							</div>

							{userStatus.roleApplicationProcess?.status === "Finished" && (
								<Button
									disabled={userStatus.isLocked}
									onClick={() => {
										setEditing(true);
									}}
									label={"Ändern"}
								/>
							)}
						</>
					)}
				</Card>
			</div>
		</div>
	);
};
