import { PrivateRouteDefinition } from "../components/core/model/PrivateRouteDefinition";
import { LoginScreen } from "../screens/LoginScreen";
import { ActivationScreen } from "../screens/ActivationScreen";
import { DocCheckLoginScreen } from "../screens/DocCheckLoginScreen";
import { DocCheckLoginSuccessScreen } from "../screens/DocCheckLoginSuccessScreen";
import { ForgotPasswordScreen } from "../screens/ForgotPasswordScreen";
import { ResetPasswordScreen } from "../screens/ResetPasswordScreen";
import { NotLoggedInPlaceholder } from "../components/auth/NotLoggedInPlaceholder";
import { SignupProcessScreen } from "../screens/SignupProcessScreen";
import { LOGIN_WITH_TOKEN_PATH, TokenLoginScreen } from "../screens/TokenLoginScreen";
import { NotAuthorizedModuleRoutes } from "./NotAuthorizedModuleRoutes";

export const FORGOT_PASSWORD_PATH = "/forgot-password/:brandId";

export const RESET_PASSWORD_PATH = "/reset-password/:token/:brandId";
export const RESET_PASSWORD_PATH_WITHOUT_BRAND = "/reset-password/:token";
export const LOGIN_PATH = "/login/:brandId/:redirectBase64?";
export const REGISTRATION_PATH = "/registration/:brandId";
export const DOCCHECK_LOGIN_PATH = "/doccheck/:brandId/:redirectBase64";
export const ACTIVATION_PATH = "/activate/:token/:brandId";
export const ACTIVATION_PATH_WITHOUT_BRAND = "/activate/:token";

export const NoLoginAuthModuleRoutes: PrivateRouteDefinition[] = [
	...NotAuthorizedModuleRoutes,
	{
		path: REGISTRATION_PATH,
		component: SignupProcessScreen,
	},
	{
		path: FORGOT_PASSWORD_PATH,
		component: ForgotPasswordScreen,
	},
	{
		path: "/forgot-password",
		component: ForgotPasswordScreen,
	},
	{
		path: RESET_PASSWORD_PATH,
		component: ResetPasswordScreen,
	},
	{
		path: RESET_PASSWORD_PATH_WITHOUT_BRAND,
		component: ResetPasswordScreen,
	},
	{
		path: "/test-login",
		component: LoginScreen,
	},
	{
		path: DOCCHECK_LOGIN_PATH,
		component: DocCheckLoginScreen,
	},
	{
		path: "/doccheck/success",
		component: DocCheckLoginSuccessScreen,
	},
	{
		path: ACTIVATION_PATH,
		component: ActivationScreen,
	},
	{
		path: ACTIVATION_PATH_WITHOUT_BRAND,
		component: ActivationScreen,
	},
	{
		path: LOGIN_WITH_TOKEN_PATH,
		component: TokenLoginScreen,
	},
	{
		path: LOGIN_PATH,
		component: NotLoggedInPlaceholder,
	},
	{
		path: "*",
		component: NotLoggedInPlaceholder,
	},
];
