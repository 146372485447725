/**
 * @generated SignedSource<<03431444cc297e28e462f82b310b5e93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChangePrivateAddressForm_UserFragment$data = {
  readonly id: string;
  readonly roleApplicationProcess: {
    readonly privateAddress: {
      readonly city: string;
      readonly country: InfectopharmCountry;
      readonly postalCode: string;
      readonly street: string;
    } | null;
  } | null;
  readonly " $fragmentType": "ChangePrivateAddressForm_UserFragment";
};
export type ChangePrivateAddressForm_UserFragment$key = {
  readonly " $data"?: ChangePrivateAddressForm_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangePrivateAddressForm_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangePrivateAddressForm_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleApplicationProcess",
      "kind": "LinkedField",
      "name": "roleApplicationProcess",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "privateAddress",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "5b6e77917e6ce4f0a306c39ee75c8e3d";

export default node;
