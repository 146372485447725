/**
 * @generated SignedSource<<529ee35011239e63ed04cb6a4e140e3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignupProcessStatus = "Finished" | "NeedsEmailValidation" | "NeedsNewsletterSignup" | "NeedsPersonalAndProfessionalData" | "%future added value";
export type SignupTokenStatus = "Expired" | "Used" | "Valid" | "%future added value";
export type FinalizeSignupProcessInput = {
  clientMutationId?: string | null;
  token: string;
};
export type ActivationScreen_ActivationMutation$variables = {
  input: FinalizeSignupProcessInput;
};
export type ActivationScreen_ActivationMutation$data = {
  readonly Signup: {
    readonly finalizeSignupProcess: {
      readonly signupProcess: {
        readonly id: string;
        readonly status: SignupProcessStatus;
        readonly tokenStatus: SignupTokenStatus;
      };
    } | null;
  };
};
export type ActivationScreen_ActivationMutation = {
  response: ActivationScreen_ActivationMutation$data;
  variables: ActivationScreen_ActivationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SignupMutations",
    "kind": "LinkedField",
    "name": "Signup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "FinalizeSignupProcessPayload",
        "kind": "LinkedField",
        "name": "finalizeSignupProcess",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SignupProcess",
            "kind": "LinkedField",
            "name": "signupProcess",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tokenStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivationScreen_ActivationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivationScreen_ActivationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fee4b42ab5311ff76515667337cc58bb",
    "id": null,
    "metadata": {},
    "name": "ActivationScreen_ActivationMutation",
    "operationKind": "mutation",
    "text": "mutation ActivationScreen_ActivationMutation(\n  $input: FinalizeSignupProcessInput!\n) {\n  Signup {\n    finalizeSignupProcess(input: $input) {\n      signupProcess {\n        id\n        status\n        tokenStatus\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6144bbc135505cff6f813e80ace0a4f0";

export default node;
