/**
 * @generated SignedSource<<b024459b3a552f74a532fe10cdd2935e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignupProcessStatus = "Finished" | "NeedsEmailValidation" | "NeedsNewsletterSignup" | "NeedsPersonalAndProfessionalData" | "%future added value";
export type StartSignupProcessInput = {
  brandRef: string;
  clientMutationId?: string | null;
  email: string;
  password: string;
  privacyAcceptanceText: string;
};
export type StartSignupStep_CheckEmailMutation$variables = {
  input: StartSignupProcessInput;
};
export type StartSignupStep_CheckEmailMutation$data = {
  readonly Signup: {
    readonly startSignupProcess: {
      readonly signupProcess: {
        readonly id: string;
        readonly status: SignupProcessStatus;
      };
    } | null;
  };
};
export type StartSignupStep_CheckEmailMutation = {
  response: StartSignupStep_CheckEmailMutation$data;
  variables: StartSignupStep_CheckEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SignupMutations",
    "kind": "LinkedField",
    "name": "Signup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "StartSignupProcessPayload",
        "kind": "LinkedField",
        "name": "startSignupProcess",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SignupProcess",
            "kind": "LinkedField",
            "name": "signupProcess",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StartSignupStep_CheckEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StartSignupStep_CheckEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "97e7d694d07e27c1d56d50698a4164a9",
    "id": null,
    "metadata": {},
    "name": "StartSignupStep_CheckEmailMutation",
    "operationKind": "mutation",
    "text": "mutation StartSignupStep_CheckEmailMutation(\n  $input: StartSignupProcessInput!\n) {\n  Signup {\n    startSignupProcess(input: $input) {\n      signupProcess {\n        id\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a1837c9a95e823821d327fb486e05e4";

export default node;
