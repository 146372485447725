import { PrivateRouteDefinition } from "../components/core/model/PrivateRouteDefinition";
import { UserHasNoAuthorisationScreen } from "../screens/UserHasNoAuthorisationScreen";

export const NOT_AUTHORIZED_403_PATH = "403/:brandId/:redirectBase64?";

export const NotAuthorizedModuleRoutes: PrivateRouteDefinition[] = [
	{
		path: NOT_AUTHORIZED_403_PATH,
		component: UserHasNoAuthorisationScreen,
	},
];
