/**
 * @generated SignedSource<<23e0e7a180bd98148b49de1bf51bbac4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResetPasswordInput = {
  clientMutationId?: string | null;
  newPassword: string;
  token: string;
};
export type ResetPasswordScreen_ResetPasswordMutation$variables = {
  input: ResetPasswordInput;
};
export type ResetPasswordScreen_ResetPasswordMutation$data = {
  readonly InfectopharmAuth: {
    readonly resetPassword: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type ResetPasswordScreen_ResetPasswordMutation = {
  response: ResetPasswordScreen_ResetPasswordMutation$data;
  variables: ResetPasswordScreen_ResetPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InfectopharmAuthMutations",
    "kind": "LinkedField",
    "name": "InfectopharmAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ResetPasswordPayload",
        "kind": "LinkedField",
        "name": "resetPassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordScreen_ResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordScreen_ResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "16d7964b01c684e518c36db8f873e58e",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordScreen_ResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordScreen_ResetPasswordMutation(\n  $input: ResetPasswordInput!\n) {\n  InfectopharmAuth {\n    resetPassword(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ec361f035b0991a00d50eb2699aec157";

export default node;
