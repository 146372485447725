import { ValidatedField } from "../../core/components/form/ValidatedField";
import { DefaultTextFieldComponent } from "../../core/components/form/DefaultTextInput";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import React from "react";
import { FormikState } from "formik";
import { InfectopharmCountry } from "../../../../__generated__/EditRoleForm_EditRoleMutation.graphql";
import { useDialogLogic } from "../../core/components/dialog/useDialogLogic";

export interface AddressFormState {
	company?: string;
	street?: string;
	postalCode?: string;
	city?: string;
	country?: InfectopharmCountry;
}

interface OwnProps {
	formik: FormikState<AddressFormState> & {
		setFieldTouched: (
			field: string,
			touched?: boolean,
			shouldValidate?: boolean | undefined,
		) => any;
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
	};
	userCurrentCountry?: InfectopharmCountry;
}

export const AddressFormPart = ({ formik, userCurrentCountry }: OwnProps) => {
	const { dialogComponent, showDialog } = useDialogLogic();

	const efnLabel = `${userCurrentCountry === "Deutschland" ? "EFN-Nummer" : "ÖÄK-Arztnummer"}`;

	return (
		<>
			{dialogComponent}
			<h2>Dienstliche Anschrift</h2>
			<ValidatedField<AddressFormState, string>
				name={"company"}
				label={"Praxis / Institution"}
				iconClass={"pi-street"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<AddressFormState, string>
				name={"street"}
				label={"Straße und Hausnummer"}
				iconClass={"pi-street"}
				formikConfig={formik}
				required={true}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<AddressFormState, string>
				name={"postalCode"}
				label={"PLZ"}
				iconClass={"pi-street"}
				formikConfig={formik}
				required={true}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<AddressFormState, string>
				name={"city"}
				label={"Stadt"}
				iconClass={"pi-street"}
				formikConfig={formik}
				required={true}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<AddressFormState, string>
				name={"country"}
				label={"Land"}
				iconClass={"pi-street"}
				formikConfig={formik}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<div>
							<Dropdown
								name={fieldName}
								value={fieldValue}
								onChange={(e) => {
									if (e.value !== userCurrentCountry) {
										showDialog({
											title: `Warnung`,
											content: `Wenn Sie das Land zu ${e.value} wechseln, wird Ihre ${efnLabel} entfernt!`,
											affirmativeText: "Ok",
											dialogCallback: () => {},
										});
									}

									updateField(e.value);
								}}
								options={[
									{ value: "Deutschland", label: "Deutschland" },
									{
										value: "Oesterreich",
										label: "Österreich",
									},
								]}
								className={classNames({ "p-invalid": !isValid })}
							/>
						</div>
					);
				}}
			/>
		</>
	);
};
