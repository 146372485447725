import React from "react";
import { Message } from "primereact/message";
import { graphql } from "babel-plugin-relay/macro";
import { Disposable, useFragment, useMutation, UseMutationConfig } from "react-relay";
import { NeedsEmailValidationStep_ActivationMutation } from "../../../../__generated__/NeedsEmailValidationStep_ActivationMutation.graphql";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import styled from "styled-components";
import { NeedsEmailValidationStep_BrandFragment$key } from "../../../../__generated__/NeedsEmailValidationStep_BrandFragment.graphql";

const BRAND_FRAGMENT = graphql`
	fragment NeedsEmailValidationStep_BrandFragment on Brand {
		id
		availableRoles
		brandColorHex
	}
`;

const ACTIVATION_EMAIL_RESEND = graphql`
	mutation NeedsEmailValidationStep_ActivationMutation($input: ResendActivationMutationInput!) {
		InfectopharmAuth {
			userResendActivationEmail(input: $input) {
				clientMutationId
			}
		}
	}
`;

const ResendActivationButton = styled(Button)`
	background-color: transparent !important;
	:hover {
		background-color: transparent !important;
	}
`;

const MessageContent = (props: {
	signupProcessId: string;
	brandId: string;
	resendActivationEmail: (
		config: UseMutationConfig<NeedsEmailValidationStep_ActivationMutation>,
	) => Disposable;
	isResending: boolean;
}) => {
	return (
		<p>
			Sie müssen Ihre E-Mail Adresse bestätigen, um Ihr Konto freizuschalten. Bitte sehen Sie
			in Ihrem E-Mail Postfach nach und folgen Sie den Anweisungen in der E-Mail, die wir
			Ihnen gesendet haben.
			<br />
			<br /> Keine Bestätigungs-E-Mail erhalten?{" "}
			<ResendActivationButton
				label={"Bestätigungs-E-Mail erneut senden"}
				disabled={props.isResending}
				className="p-button-text p-0"
				onClick={() =>
					props.resendActivationEmail({
						variables: {
							input: {
								signUpProcessId: props.signupProcessId,
								brandId: props.brandId,
							},
						},
						onCompleted: () => {
							toast("Bestätigungs-E-Mail wurde erfolgreich versendet", {
								type: "success",
							});
						},
					})
				}
			/>
		</p>
	);
};

export const NeedsEmailValidationStep = (props: {
	signupProcessId: string;
	brandFragmentRef: NeedsEmailValidationStep_BrandFragment$key;
}) => {
	const [resendActivationEmail, isResending] =
		useMutation<NeedsEmailValidationStep_ActivationMutation>(ACTIVATION_EMAIL_RESEND);
	const brand = useFragment<NeedsEmailValidationStep_BrandFragment$key>(
		BRAND_FRAGMENT,
		props.brandFragmentRef,
	);
	return (
		<div>
			<h1 className="mb-4">Fast geschafft!</h1>

			<Message
				severity={"success"}
				content={
					<MessageContent
						signupProcessId={props.signupProcessId}
						brandId={brand.id}
						resendActivationEmail={resendActivationEmail}
						isResending={isResending}
					/>
				}
			/>
		</div>
	);
};
