import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "../../../Store";
import { Permission } from "../../../__generated__/CurrentUserSetter_Query.graphql";

export interface CurrentUserData {
	permissionsInAccount: Permission[];
	user: {
		name: string;
	};
	accounts: {
		id: string;
		name: string;
	}[];
}

export interface CurrentUserState {
	currentUser?: CurrentUserData;
}

const INITIAL_STATE: CurrentUserState = {};

const currentUserSlice = createSlice({
	name: "current-user",
	initialState: INITIAL_STATE,
	reducers: {
		setCurrentUser: (state, action: PayloadAction<CurrentUserData>) => {
			state.currentUser = action.payload;
		},
	},
});

export const { setCurrentUser } = currentUserSlice.actions;
export const CurrentUserSliceReducer = currentUserSlice.reducer;

const selectCurrentUserSlice = (state: ReduxState) => state.currentUser;

export const selectCurrentUser = createSelector(selectCurrentUserSlice, (e) => e.currentUser);

export const selectPermissionsInAccount = createSelector(
	selectCurrentUserSlice,
	(state) => state.currentUser?.permissionsInAccount || [],
);

const checkPermissions = (permissions: Permission[], necessaryPermissions: Permission[]) => {
	const isOwnerOfRoot =
		permissions.includes("UserInAccountPermission_System_Owner") &&
		permissions.includes("AccountPermission_System_Root");

	return (
		isOwnerOfRoot ||
		necessaryPermissions.every((permission) => permissions.includes(permission))
	);
};

export const selectHasPermission = createSelector(
	[
		selectPermissionsInAccount,
		(state: any, necessaryPermission: Permission) => necessaryPermission,
	],
	(permissions, necessaryPermission) => checkPermissions(permissions, [necessaryPermission]),
);

export const selectHasPermissions = createSelector(
	[
		selectPermissionsInAccount,
		(state: any, necessaryPermissions: Permission[]) => necessaryPermissions,
	],
	(permissions, necessaryPermissions) => checkPermissions(permissions, necessaryPermissions),
);

export const selectAccountMetaNameByBase64DecodedAccountId = createSelector(
	selectCurrentUserSlice,
	(e) => (id: string) =>
		e.currentUser?.accounts.find((a) => atob(a.id).includes(id))?.name || "Account Not Found",
);
