import { RenderConfig } from "./ValidatedField";
import { AutoComplete, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { useState } from "react";
import styled from "styled-components";
import { BrandColorProps } from "../../../signup/SignUpProcessDialog";

export function AutoCompleteTextInput({
	items,
	renderConfig: { fieldValue, updateField },
	brandColor,
}: {
	items: string[];
	renderConfig: RenderConfig<string>;
	brandColor?: string;
}) {
	const [suggestions, setSuggestions] = useState<string[]>([]);

	const search = (event: AutoCompleteCompleteEvent) => {
		setSuggestions(
			items.filter((e: string) => e.toLowerCase().includes(event.query.toLowerCase())),
		);
	};

	return (
		<StyledAutoComplete
			brandColor={brandColor}
			value={fieldValue}
			suggestions={suggestions}
			completeMethod={search}
			onChange={(e) => {
				updateField(e.value);
			}}
			forceSelection
			dropdown
		/>
	);
}

const StyledAutoComplete = styled(AutoComplete)<BrandColorProps>`
	.p-button {
		background-color: ${(props) => props.brandColor};
	}
`;
