import { useMatch, useNavigate } from "react-router-dom";
import { NonAuthScreenBase } from "../components/core/components/base/NonAuthScreenBase";
import { DOCCHECK_LOGIN_PATH } from "../routes/NoLoginAuthModuleRoutes";
import { useEffect } from "react";
import { selectCurrentAccountId, selectLoginData } from "../redux/slices/AuthSlice";
import { useSelector } from "react-redux";

export const DocCheckRedirectScreen = () => {
	const navigate = useNavigate();
	const loginData = useSelector(selectLoginData);
	const accountId = useSelector(selectCurrentAccountId);

	const match = useMatch(DOCCHECK_LOGIN_PATH);

	useEffect(() => {
		if (match?.params.redirectBase64 && match?.params.redirectBase64 !== "undefined") {
			const href = atob(match?.params.redirectBase64.replace("-", "+").replace("_", "/"));
			const url = new URL(href);
			url.searchParams.set("refreshToken", loginData?.refreshToken || "");
			url.searchParams.set("accountId", accountId || "");
			window.location.href = url.toString();
		} else {
			navigate("/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<NonAuthScreenBase>
			<div>Leite weiter...</div>
		</NonAuthScreenBase>
	);
};
