import { JwtClaimData, JwtTokenData } from "../../../redux/slices/AuthSlice";
import { decodeToken } from "react-jwt";

export const redirectAfterAuthentication = (redirectBase64: string, tokenData: JwtTokenData) => {
	let cacheImmuneRedirectBase64: string = redirectBase64;

	// protect paedia users with cached webapps after switching paedia domain
	// TODO: remove this workaround after 01.06.2024
	if (redirectBase64 === "aHR0cHM6Ly9sZXJucG9ydGFsLnBhZWRpYS5kZS8=")
		cacheImmuneRedirectBase64 = "aHR0cHM6Ly9tZWluLnBhZWRpYWFrYWRlbWllLmRlLw==";

	const href = atob(cacheImmuneRedirectBase64.replace("-", "+").replace("_", "/"));
	const url = new URL(href);

	const claimData = decodeToken<JwtClaimData>(tokenData.accessToken);

	url.searchParams.set("accountId", claimData?.accountId as string);
	url.searchParams.set("refreshToken", tokenData.refreshToken);

	window.location.href = url.toString();
};
