import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
	RESET_PASSWORD_PATH,
	RESET_PASSWORD_PATH_WITHOUT_BRAND,
} from "../routes/NoLoginAuthModuleRoutes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../components/core/components/form/ValidatedField";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { ResetPasswordScreen_Query } from "../../__generated__/ResetPasswordScreen_Query.graphql";
import { ResetPasswordScreen_ResetPasswordMutation } from "../../__generated__/ResetPasswordScreen_ResetPasswordMutation.graphql";
import { Password } from "primereact/password";
import { NonAuthScreenBase } from "../components/core/components/base/NonAuthScreenBase";
import styled from "styled-components";

const QUERY = graphql`
	query ResetPasswordScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Brand {
				name
				brandUrl
			}
			...NonAuthScreenBase_BrandFragment
		}
	}
`;

const FORGOT_PASSWORD_MUTATION = graphql`
	mutation ResetPasswordScreen_ResetPasswordMutation($input: ResetPasswordInput!) {
		InfectopharmAuth {
			resetPassword(input: $input) {
				clientMutationId
			}
		}
	}
`;

interface FormState {
	password: string;
	password2: string;
}

export const ResetPasswordScreen = () => {
	const match = useMatch(RESET_PASSWORD_PATH);
	const matchWithoutBrand = useMatch(RESET_PASSWORD_PATH_WITHOUT_BRAND);
	const token = match?.params.token || matchWithoutBrand?.params.token;

	const data = useLazyLoadQuery<ResetPasswordScreen_Query>(QUERY, {
		id: match?.params.brandId!,
		skip: !match?.params.brandId,
	});
	const [forgotPassword, inFlight] =
		useMutation<ResetPasswordScreen_ResetPasswordMutation>(FORGOT_PASSWORD_MUTATION);
	const [isSuccessful, setSuccessful] = useState<boolean>(false);
	const [showNewRequestBtn, setShowNewRequestBtn] = useState<boolean>(false);

	const formik = useFormik<FormState>({
		initialValues: {
			password: "",
			password2: "",
		},
		validationSchema: Yup.object().shape({
			password: Yup.string()
				.min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
				.required("Das Feld Passwort wird benötigt"),
			password2: Yup.string().oneOf(
				[Yup.ref("password"), undefined],
				"Passwörter müssen übereinstimmen.",
			),
		}),
		onSubmit: (values) => {
			forgotPassword({
				variables: {
					input: {
						token: token || "",
						newPassword: values.password,
					},
				},
				onCompleted: () => {
					setSuccessful(true);
					setShowNewRequestBtn(false);
				},
				onError: () => {
					setShowNewRequestBtn(true);
				},
			});
		},
	});

	if (!token) {
		return (
			<NonAuthScreenBase>
				<h1 className="mb-4">Password zurücksetzen</h1>
				<Message severity="error" text="Dieser Link ist nicht gültig." />
			</NonAuthScreenBase>
		);
	}

	return (
		<NonAuthScreenBase brandFragmentRef={data.node}>
			<h1 className="mb-4">Password zurücksetzen</h1>
			<div className="text-sm mb-4">
				<p>
					Hier können Sie Ihr Password auf {data.node?.name || "infectopharm.com"}{" "}
					zurücksetzen.
				</p>
			</div>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"password"}
					label={"Passwort"}
					iconClass={"pi-lock"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<Password
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								toggleMask={false}
								feedback={true}
								autoComplete={"new-password"}
								weakLabel={"Schwach"}
								mediumLabel={"Mittel"}
								strongLabel={"Stark"}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>
				<ValidatedField<FormState, string>
					name={"password2"}
					label={"Passwort (wiederholen)"}
					iconClass={"pi-lock"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<Password
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								toggleMask={false}
								feedback={false}
								autoComplete={"new-password"}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>

				{isSuccessful ? (
					<>
						<Message
							className="mb-2"
							severity="success"
							text="Ihr Passwort wurde erfolgreich zurückgesetzt!"
						/>
						<a
							className="p-mt-2"
							href={data.node?.brandUrl || "https://www.infectopharm.com"}
							target="_blank"
							rel="noreferrer noopener"
						>
							<Button
								type="button"
								label={`Zurück zu ${data.node?.name || "infectopharm.com"}...`}
							/>
						</a>
					</>
				) : (
					<Button
						disabled={inFlight}
						type="submit"
						label="Password zurücksetzen..."
						className="p-mt-2"
					/>
				)}
			</form>
			{showNewRequestBtn && (
				<div>
					<ErrorMessage className="text-sm mb-4 mt-4">
						Achtung: Der Link, um Ihr Passwort zurückzusetzen, ist abgelaufen. Bitte
						fordern Sie einen neuen Link an, um Ihr Passwort zu ändern.
					</ErrorMessage>
					<Link
						className="text-sm"
						href={"/forgot-password"}
						target="_blank"
						rel="noreferrer noopener"
					>
						Bitte klicken Sie hier, um einen neuen Link anzufordern
					</Link>
				</div>
			)}
		</NonAuthScreenBase>
	);
};
const ErrorMessage = styled.p`
	color: #e74c3c !important;
`;
const Link = styled.a`
	color: #4faf44 !important;
`;
