import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { DashboardScreen_UserStatusFragment$key } from "../../__generated__/DashboardScreen_UserStatusFragment.graphql";
import { RolesDisplay } from "../components/dashboard/RolesDisplay";
import { DeleteMyselfButton } from "../components/dashboard/DeleteMyselfButton";
import { ChangePasswordForm } from "../components/dashboard/ChangePasswordForm";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ChangeEmailForm } from "../components/dashboard/ChangeEmailForm";
import { Newsletters } from "../components/dashboard/Newsletters";
import { SupplyEfnForm } from "../components/dashboard/SupplyEfnForm";
import { Message } from "primereact/message";
import { DashboardScreen_Query } from "../../__generated__/DashboardScreen_Query.graphql";
import { AuthScreenBase } from "../components/core/components/base/AuthScreenBase";
import { ChangePrivateAddressForm } from "../components/dashboard/ChangePrivateAddressForm";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../Store";
import { selectLoginData } from "../redux/slices/AuthSlice";
import { redirectAfterAuthentication } from "../components/auth/utils/redirect-after-authentication";
import { Loader } from "../components/core/components/Loader";
import React from "react";
import { DashboardScreen_Brand_Query } from "../../__generated__/DashboardScreen_Brand_Query.graphql";

const QUERY = graphql`
	query DashboardScreen_Query {
		Viewer {
			InfectopharmAuth {
				UserInformation {
					id
					...DashboardScreen_UserStatusFragment
				}
			}
		}
	}
`;

const USER_STATUS_FRAGMENT = graphql`
	fragment DashboardScreen_UserStatusFragment on UserInformation {
		id
		firstName
		lastName
		salutation
		title
		isLocked
		roleApplicationProcess {
			targetRole
			status
			userStatus
			privateAddress {
				street
				postalCode
				city
				country
			}
		}
		...RolesDisplay_UserStatusFragment
		...AccessToBrands_UserStatusFragment
		...Newsletters_UserStatusFragment
		...SupplyEfnForm_UserFragment
		...ChangePasswordForm_UserFragment
		...ChangeEmailForm_UserFragment
		...ChangePrivateAddressForm_UserFragment
		...DeleteMyselfButton_UserFragment
		...AuthScreenBase_UserInformationFragment
	}
`;

const BRAND_QUERY = graphql`
	query DashboardScreen_Brand_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Brand {
				id
				brandColorHex
				brandUrl
				icon {
					url
					name
				}
				...NonAuthScreenBase_BrandFragment
				...AuthScreenBase_BrandFragment
			}
		}
	}
`;

export const DashboardScreen = () => {
	const query = useLazyLoadQuery<DashboardScreen_Query>(QUERY, {});

	const user = useFragment<DashboardScreen_UserStatusFragment$key>(
		USER_STATUS_FRAGMENT,
		query.Viewer.InfectopharmAuth.UserInformation,
	);

	const params = useParams<{ brandId: string; redirectBase64?: string }>();
	const loginData = useTypedSelector(selectLoginData);

	const brandIdParam = params.brandId ?? process.env.REACT_APP_WISSENWIRKT_ID;

	const brandPlatformQuery = useLazyLoadQuery<DashboardScreen_Brand_Query>(BRAND_QUERY, {
		id: brandIdParam || "",
		skip: !brandIdParam,
	});

	if (params.redirectBase64 && loginData) {
		// if routes has success, that means its a redirect from doccheck successful login
		if (!params.redirectBase64?.includes("success")) {
			const loginData = useTypedSelector(selectLoginData);

			redirectAfterAuthentication(params.redirectBase64, loginData!);
		}
	}

	const isPaediaBrandPlatform = brandPlatformQuery.node?.id === process.env.REACT_APP_PAEDIA_ID;
	const isUserStatusActive = user.roleApplicationProcess?.userStatus === "Active";
	const isProcessStatusFinished = user.roleApplicationProcess?.status === "Finished";

	let salutationText = "";

	switch (user.salutation) {
		case "Mr":
			salutationText = "Herr";
			break;
		case "Mrs":
			salutationText = "Frau";
			break;
		default:
			break;
	}

	if (user.roleApplicationProcess?.targetRole === "Doccheck") {
		return (
			<AuthScreenBase
				brandPlatformFragmentRef={brandPlatformQuery.node!}
				userFragmentRef={user}
			>
				<h1>Hallo DocCheck-Benutzer</h1>
				<p>Dieser Bereich ist nur normal registrierten Benutzern zugänglich.</p>
			</AuthScreenBase>
		);
	}

	return (
		<>
			{params.redirectBase64 && loginData && <Loader />}
			{!params.redirectBase64 && loginData && (
				<AuthScreenBase
					brandPlatformFragmentRef={brandPlatformQuery.node!}
					userFragmentRef={user}
				>
					<h1 className="mt-0">
						Hallo {salutationText ? salutationText + " " : ""}{" "}
						{user.title ? user.title + " " : ""}{" "}
						{user.firstName ? user.firstName + " " : ""}{" "}
						{user.lastName ? user.lastName + " " : ""}
					</h1>
					<p className="mb-4">
						Sie befinden sich in Ihrem Benutzerkonto. Hier können Sie Ihre persönlichen
						Daten einsehen und aktualisieren.{" "}
					</p>

					{user.isLocked && (
						<Message
							className="w-12 mb-5"
							severity="error"
							text={"Dieser Account wurde vom Admin zur Bearbeitung gesperrt."}
						/>
					)}

					<SupplyEfnForm userFragmentRef={user} />

					<RolesDisplay userStatusFragmentRef={user} />

					{/*<AccessToBrands userStatusFragmentRef={user}/>*/}

					<Newsletters userStatusFragmentRef={user} />

					<h2>Kontofunktionen</h2>
					<Accordion>
						{isPaediaBrandPlatform && isUserStatusActive && isProcessStatusFinished && (
							<AccordionTab header={"Privatadresse ändern"}>
								<ChangePrivateAddressForm userFragmentRef={user} />
							</AccordionTab>
						)}
						<AccordionTab header={"Passwort ändern"}>
							<ChangePasswordForm userFragmentRef={user} />
						</AccordionTab>
						<AccordionTab header={"E-Mail ändern"}>
							<ChangeEmailForm userFragmentRef={user} />
						</AccordionTab>
						<AccordionTab header={"Konto löschen"}>
							<DeleteMyselfButton
								brandId={brandPlatformQuery.node?.id!}
								userFragmentRef={user}
							/>
						</AccordionTab>
					</Accordion>

					{/*<UserHistoryTable userFragmentRef={user}/>*/}
				</AuthScreenBase>
			)}
		</>
	);
};
