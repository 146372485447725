import styled from "styled-components";

interface ContentWrapperProps {
	brandColor?: string;
}

export const BrandedContainer = styled.div<ContentWrapperProps>`
	.p-button {
		background-color: ${(props) => props.brandColor || "#4BA540"};
		border: none;
	}

	.p-button:not(a):not(.p-disabled):hover {
		background-color: ${(props) => props.brandColor || "#4BA540"};
		filter: brightness(110%);
	}
`;
