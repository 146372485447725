import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AuthSliceReducer } from "./app/redux/slices/AuthSlice";
import { SignUpProcessSliceReducer } from "./app/redux/slices/SignUpProcessSlice";
import { CurrentUserSliceReducer } from "./app/redux/slices/CurrentUserSlice";

export const ReduxStore = configureStore({
	reducer: {
		auth: AuthSliceReducer,
		currentUser: CurrentUserSliceReducer,
		signUpProcess: SignUpProcessSliceReducer,
	},
});
export type ReduxState = ReturnType<typeof ReduxStore.getState>;
export type TypedDispatch = typeof ReduxStore.dispatch;

export const useTypedDispatch: () => TypedDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
