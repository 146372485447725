import { InfectopharmCountry } from "../../../../__generated__/MTARoleApplicationForm_ApplyRoleMutation.graphql";
import { InfectopharmRole } from "../../../../__generated__/PersonalAndProfessionalDataStep_BrandFragment.graphql";

export type OmittedInfectopharmRoles = Exclude<
	InfectopharmRole,
	"%future added value" | "Doccheck"
>;

const specialitiesAvailableInGermany: Record<OmittedInfectopharmRoles, string[]> = {
	Doctor: [
		"Allgemeinmedizin",
		"Pathologie",
		"Anästhesiologie",
		"Innere Medizin / Gastroenterologie",
		"Biochemie",
		"Innere Medizin / Kardiologie",
		"Chirurgie / Viszeralchirurgie",
		"Chirurgie / Gefäßchirurgie",
		"Chirurgie",
		"Chirurgie / Kinderchirurgie",
		"Chirurgie / Thoraxchirurgie",
		"Chirurgie / Plastische und Ästhetische Chirurgie",
		"Haut- und Geschlechtskrankheiten",
		"Innere Medizin / Endokrinologie und Diabetologie",
		"Laboratoriumsmedizin",
		"Physiologie",
		"Frauenheilkunde und Geburtshilfe / Gynäkologische Onkologie",
		"Innere Medizin / Hämatologie und Internistische Onkologie",
		"Innere Medizin / Angiologie",
		"Innere Medizin",
		"Rechtsmedizin",
		"Nuklearmedizin",
		"Arbeitsmedizin",
		"Mikrobiologie, Virologie und Infektionsepidemiologie",
		"Innere Medizin / Nephrologie",
		"Innere Medizin / Pneumologie",
		"Neurochirurgie",
		"Neurologie",
		"Frauenheilkunde und Geburtshilfe",
		"Augenheilkunde",
		"Hals-Nasen-Ohrenheilkunde",
		"Kinder- und Jugendmedizin",
		"Psychiatrie und Psychotherapie",
		"Strahlentherapie",
		"Physikalische und Rehabilitative Medizin",
		"Innere Medizin / Rheumatologie",
		"Innere und Allgemeinmedizin",
		"Urologie",
		"Orthopädie und Unfallchirurgie",
		"Chirurgie / Unfallchirurgie",
		"Chirurgie / Herzchirurgie",
		"Kinder- und Jugendmedizin / Neonatologie",
		"Kinder- und Jugendmedizin / Neuropädiatrie",
		"Kinder- und Jugendpsychiatrie",
		"Klinische Pharmakologie",
		"Radiologie",
		"Öffentliches Gesundheitswesen",
		"Kinder- und Jugendmedizin / Kinderkardiologie",
		"Neuropathologie",
		"Radiologie / Neuroradiologie",
		"Chirurgie / Mund-Kiefer-Gesichtschirurgie",
		"Psychotherapeutische Medizin",
		"Humangenetik",
		"Hygiene und Umweltmedizin",
		"Sonstiges",
		"Transfusionsmedizin",
		"Frauenheilkunde und Geburtshilfe / Gynäkologische Endokrinologie und Reproduktionsmedizin",
		"Praktischer Arzt / Praktische Ärztin",
		"Psychiatrie und Psychotherapie / Forensische Psychiatrie",
		"Psychotherapie**",
		"Psychosomatische Medizin und Psychotherapie",
		"Phoniatrie und Pädaudiologie",
		"Frauenheilkunde und Geburtshilfe / Spezielle Geburtshilfe und Perinatalmedizin",
		"Orthopädie / Rheumatologie",
		"Kinder- und Jugendmedizin / Hämatologische und Pädiatrische Onkologie",
		"Radiologie / Kinderradiologie",
		"Pharmakologie und Toxikologie",
		"Orthopädie",
	],
	Apothecary: ["Krankenhaus-Apotheke / Krankenhaus-Apotheker*in", "Apotheken / Apotheker*in"],
	Midwife: [],
	PTA: [],
	PKA: [],
	MTA: [],
	PharmaceuticalEngineer: [],
	Other: [],
	Unknown: [],
};

const specialitiesAvailableInAustria: Record<OmittedInfectopharmRoles, string[]> = {
	Doctor: [
		"Allgemein Medizin",
		"Pathologie",
		"Anästhesiologie und Intensivmedizin",
		"Chirurgie",
		"Kinderchirurgie",
		"Thoraxchirurgie",
		"Plastische Chirurgie",
		"Dermatologie",
		"Labormedizin",
		"Innere Medizin",
		"Neurologie und Psychiatrie",
		"Nuklearmedizin",
		"Arbeits- und Betriebsmedizin",
		"Lungenkrankheiten",
		"Neurochirurgie",
		"Psychiatrie und Neurologie",
		"Neurologie",
		"Frauenheilkunde und Geburtshilfe",
		"Augenheilkunde und Optometrie",
		"HNO",
		"Kinder- und Jugendheilkunde",
		"Psychiatrie",
		"Radiologie",
		"Strahlentherapie",
		"Physikalische und rehabilitative Medizin",
		"Orthopädie und Traumatologie",
		"Urologie",
		"Orthopädie und orthopädische Chirurgie",
		"Tropenmedizin",
		"Unfallchirurgie",
		"Neuropädiatrie",
		"Kinder- und Jugendpsychiatrie",
		"Kinder- und Jugendpsychiatrie und psychotherapeutische Medizin",
		"Kinder- und Jugendneuropsychiatrie",
		"Innere Medizin -  Infektiologie",
		"Zahn-/Mund-/Kieferheilkunde",
		"Psychiatrie und Psychotherapeutische Medizin",
		"In Ausbildung",
		"Hygiene und Mikrobiologie",
		"Klinische Immunologie",
		"Blutgruppendiagnostik und Transfusionsmedizin",
		"Anatomie",
	],
	Apothecary: [
		"Pharmazie KH",
		"Pharmakologie und Toxikologie",
		"Klinische Pharmakologie",
		"Pharmazie",
	],
	Midwife: [],
	PTA: [],
	PKA: [],
	MTA: [],
	PharmaceuticalEngineer: [],
	Other: [],
	Unknown: [],
};

export const getAvailableSpecialitiesList = (
	country: InfectopharmCountry,
	targetRole: OmittedInfectopharmRoles,
): string[] => {
	switch (country) {
		case "Deutschland":
			return specialitiesAvailableInGermany[targetRole];
		case "Oesterreich":
			return specialitiesAvailableInAustria[targetRole];
		default:
			return [];
	}
};
