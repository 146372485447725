import React, { PropsWithChildren } from "react";
import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../core/components/form/ValidatedField";
import { toast } from "react-toastify";
import { DefaultTextFieldComponent } from "../core/components/form/DefaultTextInput";
import {
	InfectopharmCountry,
	SupplyEfnForm_UserFragment$key,
} from "../../../__generated__/SupplyEfnForm_UserFragment.graphql";
import { SupplyEfnForm_SupplyEfnMutation } from "../../../__generated__/SupplyEfnForm_SupplyEfnMutation.graphql";
import { useDialogLogic } from "../core/components/dialog/useDialogLogic";
import { Card } from "primereact/card";
import { EfnValidationHelper } from "../signup/helpers/countryRelatedHelpers";

const USER_FRAGMENT = graphql`
	fragment SupplyEfnForm_UserFragment on UserInformation {
		id
		efnNumber
		isLocked
		roleApplicationProcess {
			targetRole
			address {
				country
			}
		}
	}
`;

const CHANGE_PASSWORD_MUTATION = graphql`
	mutation SupplyEfnForm_SupplyEfnMutation($input: SupplyEfnNumberInput!) {
		InfectopharmAuth {
			supplyEfnNumber(input: $input) {
				clientMutationId
				userInformation {
					id
					...DashboardScreen_UserStatusFragment
				}
			}
		}
	}
`;

interface FormState {
	efnNumber: string;
	country: InfectopharmCountry;
	targetRole: string;
}

interface OwnProps {
	userFragmentRef: SupplyEfnForm_UserFragment$key;
	onSuccess?: () => void;
}

export const SupplyEfnForm = ({
	userFragmentRef,
	onSuccess,
	children,
}: PropsWithChildren<OwnProps>) => {
	const user = useFragment<SupplyEfnForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef);
	const [supplyEfnNumber, isChanging] =
		useMutation<SupplyEfnForm_SupplyEfnMutation>(CHANGE_PASSWORD_MUTATION);

	const { dialogComponent, showDialog } = useDialogLogic();

	const efnLabel = `${
		user.roleApplicationProcess?.address.country === "Deutschland"
			? "EFN-Nummer"
			: "ÖÄK-Arztnummer"
	}`;

	const formik = useFormik<FormState>({
		initialValues: {
			efnNumber: "",
			targetRole: user.roleApplicationProcess?.targetRole || "Unknown",
			country: user.roleApplicationProcess?.address.country || "Unknown",
		},
		validationSchema: Yup.object().shape({
			efnNumber: EfnValidationHelper,
			targetRole: Yup.string().notRequired(),
			country: Yup.string().notRequired(),
		}),
		onSubmit: (values) => {
			showDialog({
				title: `${efnLabel} einreichen`,
				content: `Sie reichen die ${efnLabel} ${values.efnNumber} ein. Diese Nummer wird mit Ihrem Konto verknüpft und kann nicht geändert werden. Bitte überprüfen Sie die Richtigkeit der Nummer.`,
				affirmativeText: "Einreichen",
				negativeText: "Abbrechen",
				dialogCallback: (result) => {
					if (result === "Accept") {
						supplyEfnNumber({
							variables: {
								input: {
									efnNumber: values.efnNumber,
								},
							},
							onCompleted: () => {
								toast(`${efnLabel} erfolgreich eingereicht.`, { type: "success" });
								formik.resetForm();
								if (onSuccess) onSuccess();
							},
						});
					}
				},
			});
		},
	});

	if (user.efnNumber) {
		return (
			<Card className="mb-4">
				{`Ihre ${efnLabel} ist ${user.efnNumber}`}.{children}
			</Card>
		);
	}

	if (user.roleApplicationProcess?.targetRole !== "Doctor") {
		return null;
	}

	return (
		<Card className="mb-4">
			<h2>{efnLabel} einreichen</h2>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				{dialogComponent}
				<ValidatedField<FormState, string>
					name={"efnNumber"}
					label={efnLabel}
					required={false}
					formikConfig={formik}
					component={DefaultTextFieldComponent}
				/>
				<Button disabled={isChanging || user.isLocked} label={"Einreichen"} />
			</form>
		</Card>
	);
};
