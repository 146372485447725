/**
 * @generated SignedSource<<ea83828b505dad49d55d70848d04e075>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "Unknown" | "%future added value";
export type InfectopharmRole = "Apothecary" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "Unknown" | "%future added value";
export type RegistrationDisplayLocation = "Profile" | "Registration" | "%future added value";
export type Salutation = "Divers" | "Mr" | "Mrs" | "Unknown" | "%future added value";
export type SignupProcessStatus = "Finished" | "NeedsEmailValidation" | "NeedsNewsletterSignup" | "NeedsPersonalAndProfessionalData" | "%future added value";
export type ProvidePersonalAndProfessionalDataInput = {
  address: AddressInput;
  brandId: string;
  clientMutationId?: string | null;
  contactDetails: ContactDetailsInput;
  efnNumber?: string | null;
  fachrichtung?: string | null;
  firstName: string;
  id: string;
  lastName: string;
  salutation: Salutation;
  targetRole: InfectopharmRole;
  title: string;
};
export type AddressInput = {
  city: string;
  company?: string | null;
  country: InfectopharmCountry;
  postalCode: string;
  street: string;
};
export type ContactDetailsInput = {
  faxNumber?: string | null;
  phoneNumber: string;
};
export type SignUpProcessDialog_ProvidePersonalDataMutation$variables = {
  input: ProvidePersonalAndProfessionalDataInput;
};
export type SignUpProcessDialog_ProvidePersonalDataMutation$data = {
  readonly Signup: {
    readonly providePersonalAndProfessionalData: {
      readonly signupProcess: {
        readonly id: string;
        readonly newsletters: ReadonlyArray<{
          readonly countries: ReadonlyArray<InfectopharmCountry>;
          readonly description: string;
          readonly id: string;
          readonly name: string;
          readonly registrationDisplayLocations: ReadonlyArray<RegistrationDisplayLocation>;
        }>;
        readonly status: SignupProcessStatus;
      };
    } | null;
  };
};
export type SignUpProcessDialog_ProvidePersonalDataMutation = {
  response: SignUpProcessDialog_ProvidePersonalDataMutation$data;
  variables: SignUpProcessDialog_ProvidePersonalDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SignupMutations",
    "kind": "LinkedField",
    "name": "Signup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "ProvidePersonalAndProfessionalDataPayload",
        "kind": "LinkedField",
        "name": "providePersonalAndProfessionalData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SignupProcess",
            "kind": "LinkedField",
            "name": "signupProcess",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Newsletter",
                "kind": "LinkedField",
                "name": "newsletters",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registrationDisplayLocations",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "countries",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignUpProcessDialog_ProvidePersonalDataMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignUpProcessDialog_ProvidePersonalDataMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ea36274eb19c8e8e944f3d10d9b1583d",
    "id": null,
    "metadata": {},
    "name": "SignUpProcessDialog_ProvidePersonalDataMutation",
    "operationKind": "mutation",
    "text": "mutation SignUpProcessDialog_ProvidePersonalDataMutation(\n  $input: ProvidePersonalAndProfessionalDataInput!\n) {\n  Signup {\n    providePersonalAndProfessionalData(input: $input) {\n      signupProcess {\n        id\n        status\n        newsletters {\n          id\n          name\n          description\n          registrationDisplayLocations\n          countries\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3c20eab6615dba6283061a7fdc000029";

export default node;
