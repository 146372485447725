import React, { useState } from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { Dropdown } from "primereact/dropdown";
import { ApothecaryRoleApplicationForm } from "./ApothecaryRoleApplicationForm";
import { DoctorRoleApplicationForm } from "./DoctorRoleApplicationForm";
import { RoleUnlockProcess_UserInformationFragment$key } from "../../../../__generated__/RoleUnlockProcess_UserInformationFragment.graphql";
import { InfectopharmRole } from "../../../../__generated__/RolesDisplay_UserStatusFragment.graphql";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { RoleTranslations } from "../../core/i18n/Translations";
import { PTARoleApplicationForm } from "./PTARoleApplicationForm";
import { MidwifeRoleApplicationForm } from "./MidwifeRoleApplicationForm";
import { MTARoleApplicationForm } from "./MTARoleApplicationForm";
import { PKARoleApplicationForm } from "./PKARoleApplicationForm";
import { PharmaceuticalEngineerRoleApplicationForm } from "./PharmaceuticalEngineerRoleApplicationForm";

const USER_STATUS_FRAGMENT = graphql`
	fragment RoleUnlockProcess_UserInformationFragment on UserInformation {
		signedUpOverBrand {
			icon {
				url
			}
			availableRoles
			brandUrl

			...ApothecaryRoleApplicationForm_BrandFragment
			...DoctorRoleApplicationForm_BrandFragment
			...PTARoleApplicationForm_BrandFragment
			...MidwifeRoleApplicationForm_BrandFragment
			...MTARoleApplicationForm_BrandFragment
			...PKARoleApplicationForm_BrandFragment
			...PharmaceuticalEngineerRoleApplicationForm_BrandFragment
		}
	}
`;

interface OwnProps {
	onFinished?: (brandUrl?: string) => void;

	applicationToken?: string;
	userInformationFragment: RoleUnlockProcess_UserInformationFragment$key;
}

export const RoleUnlockProcess = ({
	applicationToken,
	userInformationFragment,
	onFinished,
}: OwnProps) => {
	const userInformation = useFragment<RoleUnlockProcess_UserInformationFragment$key>(
		USER_STATUS_FRAGMENT,
		userInformationFragment,
	);

	const [applyingForRole, setApplyingForRole] = useState<InfectopharmRole | undefined>(undefined);

	return (
		<div>
			<h1>Registrierung abschließen</h1>

			<div className="p-fluid">
				<label htmlFor={"applyingForRole"}>Ich bin ein</label>
				<div>
					<Dropdown
						name={"applyingForRole"}
						value={applyingForRole}
						onChange={(e) => setApplyingForRole(e.value)}
						className="mb-3"
						options={(userInformation.signedUpOverBrand?.availableRoles
							? [...userInformation.signedUpOverBrand?.availableRoles, "Other"]
							: ["Apothecary", "Doctor", "Midwife", "PTA", "PKA", "Other"]
						).map((role) => {
							return {
								value: role,
								label: RoleTranslations[role] || role,
							};
						})}
					/>
				</div>
				{applyingForRole === undefined && (
					<Button disabled={true} type="submit" label="Weiter" />
				)}
			</div>

			{applyingForRole === "Apothecary" && (
				<ApothecaryRoleApplicationForm
					brandFragmentRef={userInformation.signedUpOverBrand}
					onFinished={onFinished}
					applicationToken={applicationToken}
				/>
			)}
			{applyingForRole === "Doctor" && (
				<DoctorRoleApplicationForm
					brandFragmentRef={userInformation.signedUpOverBrand}
					onFinished={onFinished}
					applicationToken={applicationToken}
				/>
			)}

			{applyingForRole === "PTA" && (
				<PTARoleApplicationForm
					brandFragmentRef={userInformation.signedUpOverBrand}
					onFinished={onFinished}
					applicationToken={applicationToken}
				/>
			)}

			{applyingForRole === "Midwife" && (
				<MidwifeRoleApplicationForm
					brandFragmentRef={userInformation.signedUpOverBrand}
					onFinished={onFinished}
					applicationToken={applicationToken}
				/>
			)}

			{applyingForRole === "MTA" && (
				<MTARoleApplicationForm
					brandFragmentRef={userInformation.signedUpOverBrand}
					onFinished={onFinished}
					applicationToken={applicationToken}
				/>
			)}

			{applyingForRole === "PKA" && (
				<PKARoleApplicationForm
					brandFragmentRef={userInformation.signedUpOverBrand}
					onFinished={onFinished}
					applicationToken={applicationToken}
				/>
			)}

			{applyingForRole === "PharmaceuticalEngineer" && (
				<PharmaceuticalEngineerRoleApplicationForm
					brandFragmentRef={userInformation.signedUpOverBrand}
					onFinished={onFinished}
					applicationToken={applicationToken}
				/>
			)}

			{applyingForRole === "Other" && (
				<Message
					className="p-mb-2"
					severity="error"
					text={`Lieber Interessent, es tut uns leid, wir können für Ihren Fachkreis keine speziellen Inhalte zur Verfügung stellen. Aus diesem Grund ist eine Registrierung leider nicht möglich. Sollten Sie Fragen an uns haben, kontaktieren Sie uns bitte unter kontakt@${
						userInformation.signedUpOverBrand?.brandUrl || "infectopharm.com"
					}`}
				/>
			)}
		</div>
	);
};
