import { ValidatedField } from "../../core/components/form/ValidatedField";
import { DefaultTextFieldComponent } from "../../core/components/form/DefaultTextInput";
import React from "react";
import { FormikState } from "formik";

export interface ContactDetailsFormState {
	phone: string;
	fax?: string;
}

interface OwnProps {
	formik: FormikState<ContactDetailsFormState> & {
		setFieldTouched: (
			field: string,
			touched?: boolean,
			shouldValidate?: boolean | undefined,
		) => any;
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
	};
}

export const ContactDetailsFormPart = ({ formik }: OwnProps) => {
	return (
		<>
			<h2>Dienstliche Kontaktdaten</h2>
			<ValidatedField<ContactDetailsFormState, string>
				name={"phone"}
				label={"Telefonnummer"}
				iconClass={"pi-phone"}
				formikConfig={formik}
				required={true}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<ContactDetailsFormState, string>
				name={"fax"}
				label={"Faxnummer"}
				iconClass={"pi-fax"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
		</>
	);
};
